unit DonorSalesForm;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  DonorBaseViewForm,
  Data.DB,
  WEBLib.DB,
  Vcl.Controls,
  WEBLib.WebCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.Grids,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.CDS,
  WEBLib.Lists,
  Grid.Plugins,
  WEBLib.Toast,
  smx.webcore.Types, WEBLib.ComCtrls;

type
  TDonorSalesDetail = class(TDonorBaseView)
    SalesSource: TDataSource;
    SalesData: TXDataWebDataSet;
    SalesDataId: TIntegerField;
    SalesDataSheetId: TIntegerField;
    SalesDataPageNum: TIntegerField;
    SalesDataDonorId: TIntegerField;
    SalesDataShopRef: TStringField;
    SalesDataSaleDate: TDateField;
    SalesDataAmount: TFloatField;
    SalesDataCommission: TFloatField;
    SalesDataNetAmount: TFloatField;
    SalesDataClaimStatus: TStringField;
    SalesDataClaimId: TIntegerField;
    SalesDataClaimRef: TStringField;
    SalesDataClaimDate: TDateField;
    SalesDataClaimStatusChanged: TDateField;
    SalesDataNotificationId: TIntegerField;
    SalesDataRefundDate: TDateField;
    SalesDataRefundId: TIntegerField;
    SalesDataAddedBy: TIntegerField;
    SalesDataDateAdded: TDateTimeField;
    SalesDataLastUpdatedBy: TIntegerField;
    SalesDataLastUpdated: TDateTimeField;
    SalesTable: TDBTableControl;
    Shops: TClientDataSet;
    ShopsRef: TStringField;
    ShopsRegionRef: TStringField;
    ShopsName: TStringField;
    ShopsStatus: TStringField;
    ShopsLocationType: TStringField;
    SalesDataShopName: TStringField;
    RefundData: TXDataWebDataSet;
    SalesPageSize: TComboBox;
    SalesPageInfo: TLabel;
    SalesPagination: TListControl;
    SalesDataClaimStatusText: TStringField;
    SalesDataNotificationDate: TDateField;
    SalesDataNotificationSent: TBooleanField;
    SalesDataNotifcationSentText: TStringField;
    SalesDataTaxYear: TIntegerField;
    RefundDataId: TIntegerField;
    RefundDataRefundDate: TDateField;
    RefundDataRefundStatus: TStringField;
    RefundDataSales: TFloatField;
    RefundDataDonorId: TIntegerField;
    RefundDataFee: TFloatField;
    RefundDataAddedBy: TIntegerField;
    RefundDataDateAdded: TDateTimeField;
    RefundDataLastUpdatedBy: TIntegerField;
    RefundDataLastUpdated: TDateTimeField;
    RefundDataRefundAmount: TFloatField;
    RefundDataNotificationId: TIntegerField;
    RefundDataCommission: TFloatField;
    RefundSource: TDataSource;
    RefundTable: TDBTableControl;
    RefundDataStatusText: TStringField;
    SalesDataOldRef: TStringField;
    SalesDataEnteredId: TStringField;
    SalesDataSalesTaxYear: TIntegerField;
    SalesDataSalesYear: TIntegerField;
    SalesDataGiftAidValue: TFloatField;
    SalesDataClaimDateCalc: TStringField;
    procedure RefundDataAfterOpen(DataSet: TDataSet);
    procedure RefundDataCalcFields(DataSet: TDataSet);
    procedure RefundDataRefundStatusGetText(Sender: TField; var Text: string;
        DisplayText: Boolean);
    procedure SalesDataCalcFields(DataSet: TDataSet);
    procedure SalesDataSaleDateGetText(Sender: TField; var Text: string;
        DisplayText: Boolean);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FSalesGridPlugin: TGridPlugin;
  protected
    procedure AfterSetDataset; override;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

uses
  MainDataModule, AppUtils;

{$R *.dfm}

procedure TDonorSalesDetail.WebFormCreate(Sender: TObject);
begin
  inherited;
  FSalesGridPlugin := TGridPlugin.Create(SalesTable, SalesData, SalesPagination,
     SalesPageInfo, TSortPair.Create(1, sdDesc));
  FSalesGridPlugin.SetPageSize(StrToInt(SalesPageSize.Text));
  FSalesGridPlugin.SetFilterText('');
  RefundTable.Visible := False;

end;

{ TDonorSalesDetail }

procedure TDonorSalesDetail.AfterSetDataset;
var lQuery: String;
begin
  inherited;
  lQuery := Format('DonorId eq %d&$OrderBy=SaleDate desc', [DonorId]);

  FSalesGridPlugin.SetPreparedFilter(lQuery);
  RefundData.QueryString := Format('$filter=DonorId eq %d', [DonorId]);
  RefundData.Load;
end;

procedure TDonorSalesDetail.RefundDataAfterOpen(DataSet: TDataSet);
begin
  if RefundData.IsEmpty then
     RefundData.Close
  else
     RefundTable.Visible := True;
end;

procedure TDonorSalesDetail.RefundDataCalcFields(DataSet: TDataSet);
begin
  RefundDataStatusText.Value := TSysHelper.SplitOnCaps(RefundDataRefundStatus.Value);
end;

procedure TDonorSalesDetail.RefundDataRefundStatusGetText(Sender: TField; var
    Text: string; DisplayText: Boolean);
begin
  Text := TSysHelper.SplitOnCaps(Text);
end;

procedure TDonorSalesDetail.SalesDataCalcFields(DataSet: TDataSet);
var lNotificationId: Integer;
    lNotificationSent: String;
begin
  SalesDataClaimStatusText.Value := TSysHelper.SplitOnCaps(SalesDataClaimStatus.Value);

  lNotificationId := Self.SalesDataNotificationId.Value;

  if lNotificationId > 0 then
  begin
    lNotificationSent := SalesDataNotificationSent.AsString;
  if lNotificationSent = 'Y' then
     SalesDataNotifcationSentText.Value := 'Yes';

  end;
  if SalesDataClaimDate.Value <= 1 then
     SalesDataClaimDateCalc.Value := ''
  else
     SalesDataClaimDateCalc.Value := FormatDateTime('dd/mm/yyyy', SalesDataClaimDate.Value);

end;

procedure TDonorSalesDetail.SalesDataSaleDateGetText(Sender: TField; var Text:
    string; DisplayText: Boolean);
begin
  Text := FormatDateTime('dd/mm/yyyy', (Sender as TDateField).AsDateTime);
end;

procedure TDonorSalesDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SalesPageInfo := TLabel.Create('SalesPageInfo');
  SalesTable := TDBTableControl.Create('SalesTable');
  SalesPageSize := TComboBox.Create('SalesPageSize');
  SalesPagination := TListControl.Create('SalesPagination');
  RefundTable := TDBTableControl.Create('RefundTable');
  SalesSource := TDataSource.Create(Self);
  SalesData := TXDataWebDataSet.Create(Self);
  SalesDataId := TIntegerField.Create(Self);
  SalesDataSheetId := TIntegerField.Create(Self);
  SalesDataPageNum := TIntegerField.Create(Self);
  SalesDataDonorId := TIntegerField.Create(Self);
  SalesDataShopRef := TStringField.Create(Self);
  SalesDataSaleDate := TDateField.Create(Self);
  SalesDataAmount := TFloatField.Create(Self);
  SalesDataCommission := TFloatField.Create(Self);
  SalesDataNetAmount := TFloatField.Create(Self);
  SalesDataClaimStatus := TStringField.Create(Self);
  SalesDataClaimId := TIntegerField.Create(Self);
  SalesDataClaimRef := TStringField.Create(Self);
  SalesDataClaimDate := TDateField.Create(Self);
  SalesDataClaimStatusChanged := TDateField.Create(Self);
  SalesDataNotificationId := TIntegerField.Create(Self);
  SalesDataRefundDate := TDateField.Create(Self);
  SalesDataRefundId := TIntegerField.Create(Self);
  SalesDataAddedBy := TIntegerField.Create(Self);
  SalesDataDateAdded := TDateTimeField.Create(Self);
  SalesDataLastUpdatedBy := TIntegerField.Create(Self);
  SalesDataLastUpdated := TDateTimeField.Create(Self);
  SalesDataShopName := TStringField.Create(Self);
  SalesDataClaimStatusText := TStringField.Create(Self);
  SalesDataNotificationDate := TDateField.Create(Self);
  SalesDataNotificationSent := TBooleanField.Create(Self);
  SalesDataNotifcationSentText := TStringField.Create(Self);
  SalesDataTaxYear := TIntegerField.Create(Self);
  SalesDataOldRef := TStringField.Create(Self);
  SalesDataEnteredId := TStringField.Create(Self);
  SalesDataSalesTaxYear := TIntegerField.Create(Self);
  SalesDataSalesYear := TIntegerField.Create(Self);
  SalesDataGiftAidValue := TFloatField.Create(Self);
  SalesDataClaimDateCalc := TStringField.Create(Self);
  Shops := TClientDataSet.Create(Self);
  ShopsRef := TStringField.Create(Self);
  ShopsRegionRef := TStringField.Create(Self);
  ShopsName := TStringField.Create(Self);
  ShopsStatus := TStringField.Create(Self);
  ShopsLocationType := TStringField.Create(Self);
  RefundData := TXDataWebDataSet.Create(Self);
  RefundDataId := TIntegerField.Create(Self);
  RefundDataRefundDate := TDateField.Create(Self);
  RefundDataRefundStatus := TStringField.Create(Self);
  RefundDataSales := TFloatField.Create(Self);
  RefundDataDonorId := TIntegerField.Create(Self);
  RefundDataFee := TFloatField.Create(Self);
  RefundDataAddedBy := TIntegerField.Create(Self);
  RefundDataDateAdded := TDateTimeField.Create(Self);
  RefundDataLastUpdatedBy := TIntegerField.Create(Self);
  RefundDataLastUpdated := TDateTimeField.Create(Self);
  RefundDataRefundAmount := TFloatField.Create(Self);
  RefundDataNotificationId := TIntegerField.Create(Self);
  RefundDataCommission := TFloatField.Create(Self);
  RefundDataStatusText := TStringField.Create(Self);
  RefundSource := TDataSource.Create(Self);

  dpClaimStatus.BeforeLoadDFMValues;
  SalesPageInfo.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  SalesTable.BeforeLoadDFMValues;
  SalesPageSize.BeforeLoadDFMValues;
  SalesPagination.BeforeLoadDFMValues;
  RefundTable.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  SalesSource.BeforeLoadDFMValues;
  SalesData.BeforeLoadDFMValues;
  SalesDataId.BeforeLoadDFMValues;
  SalesDataSheetId.BeforeLoadDFMValues;
  SalesDataPageNum.BeforeLoadDFMValues;
  SalesDataDonorId.BeforeLoadDFMValues;
  SalesDataShopRef.BeforeLoadDFMValues;
  SalesDataSaleDate.BeforeLoadDFMValues;
  SalesDataAmount.BeforeLoadDFMValues;
  SalesDataCommission.BeforeLoadDFMValues;
  SalesDataNetAmount.BeforeLoadDFMValues;
  SalesDataClaimStatus.BeforeLoadDFMValues;
  SalesDataClaimId.BeforeLoadDFMValues;
  SalesDataClaimRef.BeforeLoadDFMValues;
  SalesDataClaimDate.BeforeLoadDFMValues;
  SalesDataClaimStatusChanged.BeforeLoadDFMValues;
  SalesDataNotificationId.BeforeLoadDFMValues;
  SalesDataRefundDate.BeforeLoadDFMValues;
  SalesDataRefundId.BeforeLoadDFMValues;
  SalesDataAddedBy.BeforeLoadDFMValues;
  SalesDataDateAdded.BeforeLoadDFMValues;
  SalesDataLastUpdatedBy.BeforeLoadDFMValues;
  SalesDataLastUpdated.BeforeLoadDFMValues;
  SalesDataShopName.BeforeLoadDFMValues;
  SalesDataClaimStatusText.BeforeLoadDFMValues;
  SalesDataNotificationDate.BeforeLoadDFMValues;
  SalesDataNotificationSent.BeforeLoadDFMValues;
  SalesDataNotifcationSentText.BeforeLoadDFMValues;
  SalesDataTaxYear.BeforeLoadDFMValues;
  SalesDataOldRef.BeforeLoadDFMValues;
  SalesDataEnteredId.BeforeLoadDFMValues;
  SalesDataSalesTaxYear.BeforeLoadDFMValues;
  SalesDataSalesYear.BeforeLoadDFMValues;
  SalesDataGiftAidValue.BeforeLoadDFMValues;
  SalesDataClaimDateCalc.BeforeLoadDFMValues;
  Shops.BeforeLoadDFMValues;
  ShopsRef.BeforeLoadDFMValues;
  ShopsRegionRef.BeforeLoadDFMValues;
  ShopsName.BeforeLoadDFMValues;
  ShopsStatus.BeforeLoadDFMValues;
  ShopsLocationType.BeforeLoadDFMValues;
  RefundData.BeforeLoadDFMValues;
  RefundDataId.BeforeLoadDFMValues;
  RefundDataRefundDate.BeforeLoadDFMValues;
  RefundDataRefundStatus.BeforeLoadDFMValues;
  RefundDataSales.BeforeLoadDFMValues;
  RefundDataDonorId.BeforeLoadDFMValues;
  RefundDataFee.BeforeLoadDFMValues;
  RefundDataAddedBy.BeforeLoadDFMValues;
  RefundDataDateAdded.BeforeLoadDFMValues;
  RefundDataLastUpdatedBy.BeforeLoadDFMValues;
  RefundDataLastUpdated.BeforeLoadDFMValues;
  RefundDataRefundAmount.BeforeLoadDFMValues;
  RefundDataNotificationId.BeforeLoadDFMValues;
  RefundDataCommission.BeforeLoadDFMValues;
  RefundDataStatusText.BeforeLoadDFMValues;
  RefundSource.BeforeLoadDFMValues;
  try
    Width := 851;
    Height := 865;
    Caption := 'SalesTabs';
    dpClaimStatus.DataField := 'ClaimStatusText';
    SalesPageInfo.SetParentComponent(Self);
    SalesPageInfo.Name := 'SalesPageInfo';
    SalesPageInfo.Left := 184;
    SalesPageInfo.Top := 354;
    SalesPageInfo.Width := 96;
    SalesPageInfo.Height := 15;
    SalesPageInfo.Caption := 'No data to display';
    SalesPageInfo.ElementFont := efCSS;
    SalesPageInfo.ElementPosition := epIgnore;
    SalesPageInfo.HeightStyle := ssAuto;
    SalesPageInfo.HeightPercent := 100.000000000000000000;
    SalesPageInfo.WidthStyle := ssAuto;
    SalesPageInfo.WidthPercent := 100.000000000000000000;
    CloseButton.Left := 376;
    CloseButton.Top := 520;
    SalesTable.SetParentComponent(Self);
    SalesTable.Name := 'SalesTable';
    SalesTable.Left := 168;
    SalesTable.Top := 104;
    SalesTable.Width := 593;
    SalesTable.Height := 244;
    SalesTable.HeightStyle := ssAuto;
    SalesTable.WidthStyle := ssAuto;
    SalesTable.BorderColor := clSilver;
    SalesTable.ChildOrder := 5;
    SalesTable.ElementFont := efCSS;
    SalesTable.ElementHeaderClassName := 'thead-light';
    SalesTable.ElementPosition := epIgnore;
    SalesTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    SalesTable.Columns.Clear;
    with SalesTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'SaleDate';
      Title := 'Sale Date';
    end;
    with SalesTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'Amount';
      Title := 'Amount';
    end;
    with SalesTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'Commission';
      Title := 'Commission';
    end;
    with SalesTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'NetAmount';
      Title := 'Net Amount';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'ClaimStatusText';
      Title := 'Claim Status';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'ClaimDateCalc';
      Title := 'Claim Date';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'ShopName';
      Title := 'Shop';
    end;
    with SalesTable.Columns.Add do
    begin
      DataField := 'NotifcationSentText';
      Title := 'Notification Sent';
    end;
    SalesTable.DataSource := SalesSource;
    SalesPageSize.SetParentComponent(Self);
    SalesPageSize.Name := 'SalesPageSize';
    SalesPageSize.Left := 168;
    SalesPageSize.Top := 73;
    SalesPageSize.Width := 81;
    SalesPageSize.Height := 23;
    SalesPageSize.ElementClassName := 'form-select';
    SalesPageSize.ElementFont := efCSS;
    SalesPageSize.ElementPosition := epIgnore;
    SalesPageSize.HeightStyle := ssAuto;
    SalesPageSize.HeightPercent := 100.000000000000000000;
    SalesPageSize.Text := '10';
    SalesPageSize.WidthStyle := ssAuto;
    SalesPageSize.WidthPercent := 100.000000000000000000;
    SalesPageSize.ItemIndex := 0;
    SalesPageSize.Items.BeginUpdate;
    try
      SalesPageSize.Items.Clear;
      SalesPageSize.Items.Add('10');
      SalesPageSize.Items.Add('25');
      SalesPageSize.Items.Add('50');
      SalesPageSize.Items.Add('100');
    finally
      SalesPageSize.Items.EndUpdate;
    end;
    SalesPagination.SetParentComponent(Self);
    SalesPagination.Name := 'SalesPagination';
    SalesPagination.Left := 472;
    SalesPagination.Top := 354;
    SalesPagination.Width := 289;
    SalesPagination.Height := 25;
    SalesPagination.HeightStyle := ssAuto;
    SalesPagination.WidthStyle := ssAuto;
    SalesPagination.HeightPercent := 100.000000000000000000;
    SalesPagination.WidthPercent := 100.000000000000000000;
    SalesPagination.ChildOrder := 3;
    SalesPagination.DefaultItemClassName := 'page-item';
    SalesPagination.DefaultItemLinkClassName := 'page-link';
    SalesPagination.ElementFont := efCSS;
    SalesPagination.ElementPosition := epIgnore;
    SalesPagination.ElementListClassName := 'pagination';
    SalesPagination.Style := lsPagination;
    RefundTable.SetParentComponent(Self);
    RefundTable.Name := 'RefundTable';
    RefundTable.Left := 168;
    RefundTable.Top := 385;
    RefundTable.Width := 640;
    RefundTable.Height := 98;
    RefundTable.HeightStyle := ssAuto;
    RefundTable.WidthStyle := ssAuto;
    RefundTable.BorderColor := clSilver;
    RefundTable.ChildOrder := 20;
    RefundTable.ElementFont := efCSS;
    RefundTable.ElementHeaderClassName := 'thead-light';
    RefundTable.ElementPosition := epIgnore;
    RefundTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    RefundTable.Columns.Clear;
    with RefundTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with RefundTable.Columns.Add do
    begin
      DataField := 'RefundDate';
      Title := 'Date';
    end;
    with RefundTable.Columns.Add do
    begin
      DataField := 'StatusText';
      Title := 'Status';
    end;
    with RefundTable.Columns.Add do
    begin
      DataField := 'RefundAmount';
      Title := 'Amount';
    end;
    RefundTable.DataSource := RefundSource;
    DataSource.Left := 320;
    DataSource.Top := 24;
    SalesSource.SetParentComponent(Self);
    SalesSource.Name := 'SalesSource';
    SalesSource.AutoEdit := False;
    SalesSource.DataSet := SalesData;
    SalesSource.Left := 460;
    SalesSource.Top := 24;
    SalesData.SetParentComponent(Self);
    SalesData.Name := 'SalesData';
    SetEvent(SalesData, Self, 'OnCalcFields', 'SalesDataCalcFields');
    SalesData.EntitySetName := 'Sale';
    SalesData.Connection := MainData.DataConnection;
    SalesData.Left := 394;
    SalesData.Top := 20;
    SalesDataId.SetParentComponent(SalesData);
    SalesDataId.Name := 'SalesDataId';
    SalesDataId.FieldName := 'Id';
    SalesDataSheetId.SetParentComponent(SalesData);
    SalesDataSheetId.Name := 'SalesDataSheetId';
    SalesDataSheetId.FieldName := 'SheetId';
    SalesDataPageNum.SetParentComponent(SalesData);
    SalesDataPageNum.Name := 'SalesDataPageNum';
    SalesDataPageNum.FieldName := 'PageNum';
    SalesDataDonorId.SetParentComponent(SalesData);
    SalesDataDonorId.Name := 'SalesDataDonorId';
    SalesDataDonorId.FieldName := 'DonorId';
    SalesDataShopRef.SetParentComponent(SalesData);
    SalesDataShopRef.Name := 'SalesDataShopRef';
    SalesDataShopRef.FieldName := 'ShopRef';
    SalesDataShopRef.Size := 10;
    SalesDataSaleDate.SetParentComponent(SalesData);
    SalesDataSaleDate.Name := 'SalesDataSaleDate';
    SalesDataSaleDate.FieldName := 'SaleDate';
    SetEvent(SalesDataSaleDate, Self, 'OnGetText', 'SalesDataSaleDateGetText');
    SalesDataAmount.SetParentComponent(SalesData);
    SalesDataAmount.Name := 'SalesDataAmount';
    SalesDataAmount.FieldName := 'Amount';
    SalesDataAmount.DisplayFormat := '#,##0.00';
    SalesDataCommission.SetParentComponent(SalesData);
    SalesDataCommission.Name := 'SalesDataCommission';
    SalesDataCommission.FieldName := 'Commission';
    SalesDataCommission.DisplayFormat := '#,##0.00';
    SalesDataNetAmount.SetParentComponent(SalesData);
    SalesDataNetAmount.Name := 'SalesDataNetAmount';
    SalesDataNetAmount.FieldName := 'NetAmount';
    SalesDataNetAmount.DisplayFormat := '#,##0.00';
    SalesDataClaimStatus.SetParentComponent(SalesData);
    SalesDataClaimStatus.Name := 'SalesDataClaimStatus';
    SalesDataClaimStatus.FieldName := 'ClaimStatus';
    SalesDataClaimStatus.Size := 18;
    SalesDataClaimId.SetParentComponent(SalesData);
    SalesDataClaimId.Name := 'SalesDataClaimId';
    SalesDataClaimId.FieldName := 'ClaimId';
    SalesDataClaimRef.SetParentComponent(SalesData);
    SalesDataClaimRef.Name := 'SalesDataClaimRef';
    SalesDataClaimRef.FieldName := 'ClaimRef';
    SalesDataClaimRef.Size := 255;
    SalesDataClaimDate.SetParentComponent(SalesData);
    SalesDataClaimDate.Name := 'SalesDataClaimDate';
    SalesDataClaimDate.FieldName := 'ClaimDate';
    SalesDataClaimStatusChanged.SetParentComponent(SalesData);
    SalesDataClaimStatusChanged.Name := 'SalesDataClaimStatusChanged';
    SalesDataClaimStatusChanged.FieldName := 'ClaimStatusChanged';
    SalesDataNotificationId.SetParentComponent(SalesData);
    SalesDataNotificationId.Name := 'SalesDataNotificationId';
    SalesDataNotificationId.FieldName := 'NotificationId';
    SalesDataRefundDate.SetParentComponent(SalesData);
    SalesDataRefundDate.Name := 'SalesDataRefundDate';
    SalesDataRefundDate.FieldName := 'RefundDate';
    SalesDataRefundId.SetParentComponent(SalesData);
    SalesDataRefundId.Name := 'SalesDataRefundId';
    SalesDataRefundId.FieldName := 'RefundId';
    SalesDataAddedBy.SetParentComponent(SalesData);
    SalesDataAddedBy.Name := 'SalesDataAddedBy';
    SalesDataAddedBy.FieldName := 'AddedBy';
    SalesDataDateAdded.SetParentComponent(SalesData);
    SalesDataDateAdded.Name := 'SalesDataDateAdded';
    SalesDataDateAdded.FieldName := 'DateAdded';
    SalesDataLastUpdatedBy.SetParentComponent(SalesData);
    SalesDataLastUpdatedBy.Name := 'SalesDataLastUpdatedBy';
    SalesDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    SalesDataLastUpdated.SetParentComponent(SalesData);
    SalesDataLastUpdated.Name := 'SalesDataLastUpdated';
    SalesDataLastUpdated.FieldName := 'LastUpdated';
    SalesDataShopName.SetParentComponent(SalesData);
    SalesDataShopName.Name := 'SalesDataShopName';
    SalesDataShopName.FieldKind := fkLookup;
    SalesDataShopName.FieldName := 'ShopName';
    SalesDataShopName.LookupDataSet := Shops;
    SalesDataShopName.LookupKeyFields := 'Ref';
    SalesDataShopName.LookupResultField := 'Name';
    SalesDataShopName.KeyFields := 'ShopRef';
    SalesDataShopName.Size := 75;
    SalesDataShopName.Lookup := True;
    SalesDataClaimStatusText.SetParentComponent(SalesData);
    SalesDataClaimStatusText.Name := 'SalesDataClaimStatusText';
    SalesDataClaimStatusText.FieldKind := fkCalculated;
    SalesDataClaimStatusText.FieldName := 'ClaimStatusText';
    SalesDataClaimStatusText.Size := 25;
    SalesDataClaimStatusText.Calculated := True;
    SalesDataNotificationDate.SetParentComponent(SalesData);
    SalesDataNotificationDate.Name := 'SalesDataNotificationDate';
    SalesDataNotificationDate.FieldName := 'NotificationDate';
    SalesDataNotificationSent.SetParentComponent(SalesData);
    SalesDataNotificationSent.Name := 'SalesDataNotificationSent';
    SalesDataNotificationSent.FieldName := 'NotificationSent';
    SalesDataNotifcationSentText.SetParentComponent(SalesData);
    SalesDataNotifcationSentText.Name := 'SalesDataNotifcationSentText';
    SalesDataNotifcationSentText.FieldKind := fkCalculated;
    SalesDataNotifcationSentText.FieldName := 'NotifcationSentText';
    SalesDataNotifcationSentText.Size := 25;
    SalesDataNotifcationSentText.Calculated := True;
    SalesDataTaxYear.SetParentComponent(SalesData);
    SalesDataTaxYear.Name := 'SalesDataTaxYear';
    SalesDataTaxYear.FieldName := 'TaxYear';
    SalesDataOldRef.SetParentComponent(SalesData);
    SalesDataOldRef.Name := 'SalesDataOldRef';
    SalesDataOldRef.FieldName := 'OldRef';
    SalesDataOldRef.Size := 10;
    SalesDataEnteredId.SetParentComponent(SalesData);
    SalesDataEnteredId.Name := 'SalesDataEnteredId';
    SalesDataEnteredId.FieldName := 'EnteredId';
    SalesDataEnteredId.Size := 10;
    SalesDataSalesTaxYear.SetParentComponent(SalesData);
    SalesDataSalesTaxYear.Name := 'SalesDataSalesTaxYear';
    SalesDataSalesTaxYear.FieldName := 'SalesTaxYear';
    SalesDataSalesYear.SetParentComponent(SalesData);
    SalesDataSalesYear.Name := 'SalesDataSalesYear';
    SalesDataSalesYear.FieldName := 'SalesYear';
    SalesDataGiftAidValue.SetParentComponent(SalesData);
    SalesDataGiftAidValue.Name := 'SalesDataGiftAidValue';
    SalesDataGiftAidValue.FieldName := 'GiftAidValue';
    SalesDataClaimDateCalc.SetParentComponent(SalesData);
    SalesDataClaimDateCalc.Name := 'SalesDataClaimDateCalc';
    SalesDataClaimDateCalc.FieldKind := fkCalculated;
    SalesDataClaimDateCalc.FieldName := 'ClaimDateCalc';
    SalesDataClaimDateCalc.Size := 15;
    SalesDataClaimDateCalc.Calculated := True;
    Shops.SetParentComponent(Self);
    Shops.Name := 'Shops';
    Shops.Left := 696;
    Shops.Top := 24;
    ShopsRef.SetParentComponent(Shops);
    ShopsRef.Name := 'ShopsRef';
    ShopsRef.FieldName := 'Ref';
    ShopsRef.Size := 5;
    ShopsRegionRef.SetParentComponent(Shops);
    ShopsRegionRef.Name := 'ShopsRegionRef';
    ShopsRegionRef.FieldName := 'RegionRef';
    ShopsRegionRef.Size := 5;
    ShopsName.SetParentComponent(Shops);
    ShopsName.Name := 'ShopsName';
    ShopsName.FieldName := 'Name';
    ShopsName.Size := 250;
    ShopsStatus.SetParentComponent(Shops);
    ShopsStatus.Name := 'ShopsStatus';
    ShopsStatus.FieldName := 'Status';
    ShopsStatus.Size := 6;
    ShopsLocationType.SetParentComponent(Shops);
    ShopsLocationType.Name := 'ShopsLocationType';
    ShopsLocationType.FieldName := 'LocationType';
    ShopsLocationType.Size := 10;
    RefundData.SetParentComponent(Self);
    RefundData.Name := 'RefundData';
    RefundData.AfterOpen := RefundDataAfterOpen;
    SetEvent(RefundData, Self, 'OnCalcFields', 'RefundDataCalcFields');
    RefundData.EntitySetName := 'Refund';
    RefundData.Connection := MainData.DataConnection;
    RefundData.Left := 536;
    RefundData.Top := 17;
    RefundDataId.SetParentComponent(RefundData);
    RefundDataId.Name := 'RefundDataId';
    RefundDataId.FieldName := 'Id';
    RefundDataRefundDate.SetParentComponent(RefundData);
    RefundDataRefundDate.Name := 'RefundDataRefundDate';
    RefundDataRefundDate.FieldName := 'RefundDate';
    RefundDataRefundStatus.SetParentComponent(RefundData);
    RefundDataRefundStatus.Name := 'RefundDataRefundStatus';
    RefundDataRefundStatus.FieldName := 'RefundStatus';
    RefundDataRefundStatus.Required := True;
    RefundDataRefundStatus.Size := 12;
    RefundDataSales.SetParentComponent(RefundData);
    RefundDataSales.Name := 'RefundDataSales';
    RefundDataSales.FieldName := 'Sales';
    RefundDataSales.Required := True;
    RefundDataDonorId.SetParentComponent(RefundData);
    RefundDataDonorId.Name := 'RefundDataDonorId';
    RefundDataDonorId.FieldName := 'DonorId';
    RefundDataDonorId.Required := True;
    RefundDataFee.SetParentComponent(RefundData);
    RefundDataFee.Name := 'RefundDataFee';
    RefundDataFee.FieldName := 'Fee';
    RefundDataFee.Required := True;
    RefundDataAddedBy.SetParentComponent(RefundData);
    RefundDataAddedBy.Name := 'RefundDataAddedBy';
    RefundDataAddedBy.FieldName := 'AddedBy';
    RefundDataDateAdded.SetParentComponent(RefundData);
    RefundDataDateAdded.Name := 'RefundDataDateAdded';
    RefundDataDateAdded.FieldName := 'DateAdded';
    RefundDataLastUpdatedBy.SetParentComponent(RefundData);
    RefundDataLastUpdatedBy.Name := 'RefundDataLastUpdatedBy';
    RefundDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    RefundDataLastUpdated.SetParentComponent(RefundData);
    RefundDataLastUpdated.Name := 'RefundDataLastUpdated';
    RefundDataLastUpdated.FieldName := 'LastUpdated';
    RefundDataRefundAmount.SetParentComponent(RefundData);
    RefundDataRefundAmount.Name := 'RefundDataRefundAmount';
    RefundDataRefundAmount.FieldName := 'RefundAmount';
    RefundDataRefundAmount.Required := True;
    RefundDataNotificationId.SetParentComponent(RefundData);
    RefundDataNotificationId.Name := 'RefundDataNotificationId';
    RefundDataNotificationId.FieldName := 'NotificationId';
    RefundDataCommission.SetParentComponent(RefundData);
    RefundDataCommission.Name := 'RefundDataCommission';
    RefundDataCommission.FieldName := 'Commission';
    RefundDataCommission.Required := True;
    RefundDataStatusText.SetParentComponent(RefundData);
    RefundDataStatusText.Name := 'RefundDataStatusText';
    RefundDataStatusText.FieldKind := fkCalculated;
    RefundDataStatusText.FieldName := 'StatusText';
    RefundDataStatusText.Size := 25;
    RefundDataStatusText.Calculated := True;
    RefundSource.SetParentComponent(Self);
    RefundSource.Name := 'RefundSource';
    RefundSource.DataSet := RefundData;
    RefundSource.Left := 616;
    RefundSource.Top := 48;
  finally
    dpClaimStatus.AfterLoadDFMValues;
    SalesPageInfo.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    SalesTable.AfterLoadDFMValues;
    SalesPageSize.AfterLoadDFMValues;
    SalesPagination.AfterLoadDFMValues;
    RefundTable.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    SalesSource.AfterLoadDFMValues;
    SalesData.AfterLoadDFMValues;
    SalesDataId.AfterLoadDFMValues;
    SalesDataSheetId.AfterLoadDFMValues;
    SalesDataPageNum.AfterLoadDFMValues;
    SalesDataDonorId.AfterLoadDFMValues;
    SalesDataShopRef.AfterLoadDFMValues;
    SalesDataSaleDate.AfterLoadDFMValues;
    SalesDataAmount.AfterLoadDFMValues;
    SalesDataCommission.AfterLoadDFMValues;
    SalesDataNetAmount.AfterLoadDFMValues;
    SalesDataClaimStatus.AfterLoadDFMValues;
    SalesDataClaimId.AfterLoadDFMValues;
    SalesDataClaimRef.AfterLoadDFMValues;
    SalesDataClaimDate.AfterLoadDFMValues;
    SalesDataClaimStatusChanged.AfterLoadDFMValues;
    SalesDataNotificationId.AfterLoadDFMValues;
    SalesDataRefundDate.AfterLoadDFMValues;
    SalesDataRefundId.AfterLoadDFMValues;
    SalesDataAddedBy.AfterLoadDFMValues;
    SalesDataDateAdded.AfterLoadDFMValues;
    SalesDataLastUpdatedBy.AfterLoadDFMValues;
    SalesDataLastUpdated.AfterLoadDFMValues;
    SalesDataShopName.AfterLoadDFMValues;
    SalesDataClaimStatusText.AfterLoadDFMValues;
    SalesDataNotificationDate.AfterLoadDFMValues;
    SalesDataNotificationSent.AfterLoadDFMValues;
    SalesDataNotifcationSentText.AfterLoadDFMValues;
    SalesDataTaxYear.AfterLoadDFMValues;
    SalesDataOldRef.AfterLoadDFMValues;
    SalesDataEnteredId.AfterLoadDFMValues;
    SalesDataSalesTaxYear.AfterLoadDFMValues;
    SalesDataSalesYear.AfterLoadDFMValues;
    SalesDataGiftAidValue.AfterLoadDFMValues;
    SalesDataClaimDateCalc.AfterLoadDFMValues;
    Shops.AfterLoadDFMValues;
    ShopsRef.AfterLoadDFMValues;
    ShopsRegionRef.AfterLoadDFMValues;
    ShopsName.AfterLoadDFMValues;
    ShopsStatus.AfterLoadDFMValues;
    ShopsLocationType.AfterLoadDFMValues;
    RefundData.AfterLoadDFMValues;
    RefundDataId.AfterLoadDFMValues;
    RefundDataRefundDate.AfterLoadDFMValues;
    RefundDataRefundStatus.AfterLoadDFMValues;
    RefundDataSales.AfterLoadDFMValues;
    RefundDataDonorId.AfterLoadDFMValues;
    RefundDataFee.AfterLoadDFMValues;
    RefundDataAddedBy.AfterLoadDFMValues;
    RefundDataDateAdded.AfterLoadDFMValues;
    RefundDataLastUpdatedBy.AfterLoadDFMValues;
    RefundDataLastUpdated.AfterLoadDFMValues;
    RefundDataRefundAmount.AfterLoadDFMValues;
    RefundDataNotificationId.AfterLoadDFMValues;
    RefundDataCommission.AfterLoadDFMValues;
    RefundDataStatusText.AfterLoadDFMValues;
    RefundSource.AfterLoadDFMValues;
  end;
end;

end.


