unit smx.Reports.Support;

interface

uses
  smx.Reports.Types;

type

  TReportSupport = class
  private
    class var FCompanyYearEnd: TCompanyYearEnd;
    class constructor Create;
    class destructor Destroy;
  public
    class procedure SetCompanyYearEnd(const AMonth, ADay: Word); overload;
    class procedure SetCompanyYearEnd(const Value: string); overload;

    class function QuarterOf(const Value: TDateTime): Word;
    class function StartOfAQuarter(const AQuarter, AYear: Word): TDateTime;
    class function StartOfTheQuarter(const Value: TDateTime): TDateTime;
    class function StartOfLastQuarter(const Value: TDateTime): TDateTime;
    class function EndOfLastQuarter(const Value: TDateTime): TDateTime;
    class function EndOfAQuarter(const AQuarter, AYear: Word): TDateTime;
    class function StartOfTaxYear(const Value: TDate): TDateTime;
    class function EndOfTaxYear(const Value: TDate): TDateTime;
    class function GetPreviousWorkingDay(const Value: TDate): TDateTime;
    class function GetStandardDate(const ADefault: TParamDefault; const AQualifier: Integer = 0): TDate;
    class function GetStandardDateTime(const ADefault: TParamDefault; const AQualifier: Integer): TDateTime;
    class function GetQualifiedDate(const ADefault: TParamDefault; const AQualifier: Integer): TDateTime;
    class function NeedsRefresh(const RefreshFreq: TRefreshFreq; const LastRefresh: TDateTime): boolean;
    class function CompanyYearEnd: TCompanyYearEnd;
    class function ACompanyYearStart(const ADate: TDate): TDate;
    class function ACompanyYearEnd(const ADate: TDate): TDate;

  end;

implementation

uses
  System.DateUtils,
  System.SysUtils;

const
  C_START_OPTIONS = [pdStartOfThisYear, pdStartOfThisMonth, pdStartOfThisWeek, pdToday, pdYesterday, pdStartOfLastYear,
    pdStartOfLastMonth, pdStartOfLastWeek, pdStartOfThisQuarter, pdStartOfLastQuarter, pdStartOfTaxYear,
    pdStartOfLastTaxYear, pdPreviousWorkingDay];
  C_END_OPTIONS = [pdYesterday, pdEndofLastYear, pdEndOfLastMonth, pdEndOfLastWeek, pdEndOfLastQuarter,
    pdEndOfLastTaxYear, pdPreviousWorkingDayEnd];
  C_QUALIFIED_OPTION = [pdIncDay, pdIncWeek, pdIncMonth, pdStartOfCompanyYear, pdEndOfCompanyYear];

  { TReportSupport }

class function TReportSupport.CompanyYearEnd: TCompanyYearEnd;
begin
  result := FCompanyYearEnd;
end;

class function TReportSupport.ACompanyYearEnd(const ADate: TDate): TDate;
begin
    Result := FCompanyYearEnd.YearEnd(ADate);
end;

class function TReportSupport.ACompanyYearStart(const ADate: TDate): TDate;
begin
  Result := FCompanyYearEnd.YearStart(ADate);
end;

class constructor TReportSupport.Create;
begin
  FCompanyYearEnd := TCompanyYearEnd.Create;
  FCompanyYearEnd.Month := 12;
  FCompanyYearEnd.Day := 31;
end;

class destructor TReportSupport.Destroy;
begin
  FCompanyYearEnd.Free;
end;

class function TReportSupport.EndOfAQuarter(const AQuarter, AYear: Word): TDateTime;
begin
  case AQuarter of
    1:
      result := EndOfAMonth(AYear, 3);
    2:
      result := EndOfAMonth(AYear, 6);
    3:
      result := EndOfAMonth(AYear, 9);
    4:
      result := EndOfAMonth(AYear, 12);
  else
    result := 0;
  end;
end;

class function TReportSupport.EndOfLastQuarter(const Value: TDateTime): TDateTime;
var
  lQuarter, lYear: Word;
begin
  lQuarter := QuarterOf(Value);
  lYear := YearOf(Value);
  if lQuarter = 1 then
  begin
    lQuarter := 4;
    lYear := lYear - 1;
  end
  else
    lQuarter := lQuarter - 1;

  result := EndOfAQuarter(lQuarter, lYear);

end;

class function TReportSupport.EndOfTaxYear(const Value: TDate): TDateTime;
var
  lYear, lMonth, lDay: Word;
begin
  DecodeDate(Value, lYear, lMonth, lDay);

  if (lMonth = 4) then
  begin
    if lDay > 5 then
      lYear := lYear + 1
  end
  else if lMonth > 4 then
  begin
    lYear := lYear + 1;
  end;

  result := EncodeDate(lYear, 4, 5);

end;

class function TReportSupport.GetPreviousWorkingDay(const Value: TDate): TDateTime;
begin
  repeat
    result := IncDay(Value, -1);
  until ((DayOf(Value) < 6)); // (not IsWeekend(result)) and (not IsBankHoliday(result)));
end;

class function TReportSupport.GetQualifiedDate(const ADefault: TParamDefault; const AQualifier: Integer): TDateTime;
begin
  result := Today;
  case ADefault of
    pdIncDay:
      result := IncDay(Today, AQualifier);
    pdIncWeek:
      result := StartOfTheWeek(IncWeek(Today, AQualifier));
    pdIncMonth:
      result := StartOfTheMOnth(IncMonth(Today, AQualifier));
  end;
end;

class function TReportSupport.GetStandardDate(const ADefault: TParamDefault; const AQualifier: Integer): TDate;
begin

  case ADefault of
    // NoParamDefault: ;
    pdStartOfThisYear:
      result := StartOfTheYear(Today);
    pdStartOfThisMonth:
      result := StartOfTheMOnth(Today);
    pdStartOfThisWeek:
      result := StartOfTheWeek(Today);
    pdToday:
      result := Today;
    pdNow:
      result := Today;
    pdYesterday:
      result := Yesterday;
    pdStartOfLastYear:
      result := StartOfTheYear(IncYear(Today, -1));
    pdEndofLastYear:
      result := EndOfTheYear(IncYear(Today, -1));
    pdStartOfLastMonth:
      result := StartOfTheMOnth(IncMonth(Today, -1));
    pdEndOfLastMonth:
      result := EndOfTheMonth(IncMonth(Today, -1));
    pdStartOfLastWeek:
      result := StartOfTheWeek(IncWeek(Today, -1));
    pdEndOfLastWeek:
      result := EndOfTheWeek(IncWeek(Today, -1));
    pdStartOfThisQuarter:
      result := StartOfTheQuarter(Today);
    pdStartOfLastQuarter:
      result := StartOfLastQuarter(Today);
    pdEndOfLastQuarter:
      result := EndOfLastQuarter(Today);
    pdStartOfTaxYear:
      result := StartOfTaxYear(Today);
    pdStartOfLastTaxYear:
      result := StartOfTaxYear(IncYear(Today, -1));
    pdEndOfLastTaxYear:
      result := EndOfTaxYear(IncYear(Today, -1));
    pdPreviousWorkingDay:
      result := GetPreviousWorkingDay(Today);
    pdPreviousWorkingDayEnd:
      result := GetPreviousWorkingDay(Today);
    pdStartOfCompanyYear:
      begin
        result := FCompanyYearEnd.CurrentYearStart;
        if AQualifier <> 0 then
          result := IncYear(result, AQualifier);
      end;
    pdEndOfCompanyYear:
      begin
        result := FCompanyYearEnd.CurrentYearEnd;
        if AQualifier <> 0 then
          result := IncYear(result, AQualifier);
      end;

    pdIncDay, pdIncWeek, pdIncMonth:
      result := GetQualifiedDate(ADefault, AQualifier);
  else
    result := 0;
  end;

end;

class function TReportSupport.GetStandardDateTime(const ADefault: TParamDefault; const AQualifier: Integer): TDateTime;
begin

  if ADefault = pdNow then
    Exit(Now);

  result := GetStandardDate(ADefault, AQualifier);

  if (ADefault in C_END_OPTIONS) then
    result := EndOfTheDay(result)
  else if (ADefault in C_START_OPTIONS) then
    result := StartOfTheDay(result);

end;

class function TReportSupport.NeedsRefresh(const RefreshFreq: TRefreshFreq; const LastRefresh: TDateTime): boolean;
begin
  result := False;
  case RefreshFreq of
//    rfManual:
//      result := False;
    rfAlways:
      result := True;
    rfHourly:
      result := (HoursBetween(Now, LastRefresh) >= 1);
    rfDaily:
      result := (DaysBetween(Now, LastRefresh) >= 1);
    rfWeekly:
      result := (WeeksBetween(Now, LastRefresh) >= 1);
    rfMonthly:
      result := (MonthsBetween(Now, LastRefresh) >= 1);
    rfAnnually:
      result := (YearsBetween(Now, LastRefresh) >= 1);
  end;
end;

class function TReportSupport.QuarterOf(const Value: TDateTime): Word;
begin
  result := ((MonthOf(Value) - 1) div 3) + 1;
end;

class procedure TReportSupport.SetCompanyYearEnd(const AMonth, ADay: Word);
begin
  if not Assigned(FCompanyYearEnd) then
     FCompanyYearEnd := TCompanyYearEnd.Create;
  FCompanyYearEnd.Month := AMonth;
  FCompanyYearEnd.Day := ADay;
end;

class procedure TReportSupport.SetCompanyYearEnd(const Value: string);
var
  lParts: TArray<string>;
begin
  if Value <> '' then
  begin
    lParts := Value.Split([',']);
    SetCompanyYearEnd(lParts[0].ToInteger, lParts[1].ToInteger);
  end;
end;

class function TReportSupport.StartOfAQuarter(const AQuarter, AYear: Word): TDateTime;
begin
  case AQuarter of
    1:
      result := StartOfAMonth(AYear, 1);
    2:
      result := StartOfAMonth(AYear, 4);
    3:
      result := StartOfAMonth(AYear, 7);
    4:
      result := StartOfAMonth(AYear, 10);
  else
    result := 0;
  end;
end;

class function TReportSupport.StartOfLastQuarter(const Value: TDateTime): TDateTime;
var
  lQuarter, lYear: Word;
begin
  lQuarter := QuarterOf(Value);
  lYear := YearOf(Value);
  if lQuarter = 1 then
  begin
    lQuarter := 4;
    lYear := lYear - 1;
  end
  else
    lQuarter := lQuarter - 1;

  result := StartOfAQuarter(lQuarter, lYear);
end;

class function TReportSupport.StartOfTaxYear(const Value: TDate): TDateTime;
var
  lYear, lMonth, lDay: Word;
begin
  DecodeDate(Value, lYear, lMonth, lDay);

  if (lMonth = 4) then
  begin
    if lDay < 6 then
      lYear := lYear - 1
  end
  else if lMonth < 4 then
  begin
    lYear := lYear - 1;
  end;

  result := EncodeDate(lYear, 4, 6);
end;

class function TReportSupport.StartOfTheQuarter(const Value: TDateTime): TDateTime;
var
  lQuarter, lYear: Word;
begin
  lQuarter := QuarterOf(Value);
  lYear := YearOf(Value);
  result := StartOfAQuarter(lQuarter, lYear);
end;

end.
