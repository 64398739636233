unit AppUtils;

interface

uses
  System.SysUtils,
  WebLib.ExtCtrls,
  XData.Web.Dataset,
  WebLib.CDS,
  JS,
  Data.DB,
  Web,
  WebLib.WebCtrls;

type
  TRegionLookUp = record
    Ref: string;
    Name: string;
  end;

  TViewOption = (voNone, voReadOnly, voEdit, voCreateAndEdit);
  TPostCodePart = (pcNotPostCode, pcPartial, pcFull);
  TValueChangeState = (vcsNoChange, vcsValid, vcsInvalid);
  TDonorIdType = (ditNotSpecified, ditId, ditOldRef, ditConsId);

  // This is a replica of TClaimStatus in Server\Entities\GA.Entities.Sales.pas
  TLocalClaimStatus = (lcsVoid, lcsDeleted, lcsUnclaimed, lcsScheduled, lcsRefundRequest, lcsRefunded, lcsNotAllowed,
    lcsOmitted, lcsSubmitted, lcsConfirmed, lcsReceived, lcsNeedsReversal, lcsReversed, lcsRejected, lcsInvalid);

  TSysHelper = class
    class function IsRef(const Value: string): Boolean;
    class function IsId(const Value: string): Boolean;
    class function IsPhoneNumber(const Value: string): Boolean;
    class function IsInteger(const Value: string): Boolean;
    class function IsNumber(const Value: string): Boolean;
    class function IsPostCode(const Value: string): TPostCodePart;
    class function FormatPostCode(const Value: string): string;
    class function IsEmailValid(const Value: string): Boolean;

    class function JobActive(const AStatus: string): Boolean;

    class function SplitOnCaps(const Value: string): string;
    class function CapitaliseFirstLetter(const Value: string): string;
    class function CapitaliseWords(const Value: string): string;

    class function ConCat(Value: array of string; const ADelim: string): string;
    class function FullName(const ADataset: TDataset): string;
    class function ShortName(const ADataset: TDataset): string;
    class function FormattedAddress(const ADataset: TDataset; const ADelim: string = ', '): string;
    class function SecondsAsTime(const ASeconds: Int64): string;
    class function DateStrToDate(const Value: string): TDate;
    class function TaxYearStr(const Value: Integer): string;

    class procedure LookUpValuesLoad(Source, Target: TLookupValues; const StartIndex: Integer = 1);
    class function FindLookUpValue(Source: TLookupValues; const AValue: string): string;
    class function LoadXDataRows(Source: TXDataWebDataset): JS.TJSArray;
    class procedure JsonToClientDatset(Source: JS.TJSArray; Target: TClientDataset);
    class procedure XDataToClientDataset(Source: TXDataWebDataset; Target: TClientDataset);

    /// <summary>
    /// This is the start year of period, so 6/4/2021 - 5/4/2021 will be 2021
    /// </summary>
    class function LastTaxYearKey: Word;
    class function TaxYearDescriptor(const TaxYearKey: Word): string;

    class function StringToClaimStatus(const Value: string): TLocalClaimStatus;

    { TODO : Needs a separate class for layout stuff }
    /// <param name="AFontAwesomeClass">
    /// this is the 'fad fa-edit' part of '&lt;i class="fad fa-edit
    /// fa-lg"&gt;&lt;/i&gt;'
    /// </param>
    class function RowActionSpan(const AParentElement: TJSHTMLElement; const
        AFontAwesomeClass: string; const ATooltip: String = ''; const
        HorizontalFlip: Boolean = False): THTMLSpan;

  end;

  TRefStatus = (RefEmpty, RefNotChecked, RefChecking, RefUnique, RefInUse);

const
  YesNo: array [Boolean] of string = ('No', 'Yes');
  SYS_DATE_FORMAT = 'dd/mm/yyyy';
  SYS_DATETIME_FORMAT = 'dd/mm/yyyy hh:nn';
  SYS_MONEY_FORMAT = '#,##0.00';
  GiftAid_Scheme: array [Boolean] of string = ('MethodA', 'Standard');
  key_tab = 9;
  key_enter = 13;
  key_space = 32;
  key_left = 37;
  key_up = 38;
  key_right = 39;
  key_down = 40;
  smWordDelimiters: array of Char = [' ', ';', ':', ',', ',', ')', '-', #10, #13, #160];
  ChangeStateOk = [vcsNoChange, vcsValid];

  Stop_Sales_Delete = [lcsRefundRequest, lcsRefunded, lcsOmitted, lcsSubmitted, lcsConfirmed, lcsReceived,
    lcsNeedsReversal, lcsReversed];

  IUTILSVC_GETREGIONS = 'IGAUtilsService.GetRegionsList';
  IUTILSVC_CHECK_SHOPREF = 'IGAUtilsService.IsShopRefUnique';
  IUTILSVC_CHECK_USERNAME = 'IGAUtilsService.IsUserNameUnique';
  IUTILSVC_SHOPLIST = 'IGAUtilsService.GetShopsList';
  IUTILSSVC_UPDATEPASSWORD = 'IGAUtilsService.UpdatePassword'; // (const CurrentPassword, NewPassword: String): String;
  IUTILSSVC_SETPASSWORD = 'IGAUtilsService.SetPassword'; // UserId, Password
  IUTILSSVC_GENERATEPASSWORD = 'IGAUtilsService.GeneratePassword'; // UserId
  IUTILSSVC_GETNEXTID = 'IGAUtilsService.GetNextSequenceNo'; // (const ASequenceName: String): Integer;
  IUTILSSVC_GETGIFTAISUSERS = 'IGAUtilsService.GetGiftAidUsers';
  // (const ASubmittedBy, AHMRCOfficial: Integer): TGiftAidUsers;
  ITUTILSSVC_GETRECUSERS = 'IGAUtilsService.GetRecordUser'; // (const AAddedBy, AUpdatedBy: Integer): TAuditUsers;
  ITUTILSSVC_GET_THINGS_TODO = 'IGAUtilsService.GetThingsToDo'; // : TList<TThingsToDo>;
  IUTILSSVC_FILEEXISTS = 'IGAUtilsService.FileExists'; // (const AStore, APath, AFile: String): Boolean;
  IUTILSSVC_IRRECEIPT = 'IGAUtilsService.GetIRReceipt'; // (const AClaimId: Integer): String;

  IDONORSVC_UNUSED_IDS = 'IDonorService.GetUnusedDonorIds'; // ShopRef : Integer

  /// <summary>
  /// Check if the DonorId is valid and whether it is used in the Donor Table
  /// </summary>
  IDONORSVC_VALIDATE_DONORID = 'IDonorService.ValidateDonorId'; // Param=DonorId (Old or New)

  /// <summary>
  /// Use to check that the New Donor Id valid and is available
  /// </summary>
  IDONORSVC_DONORID_AVAILABILITY = 'IDonorService.GetDonorIdAvailability'; // Param=DonorId (Old or New)

  IDONORSVC_DONORID_CHANGE = 'IDonorService.ChangeDonorId'; // Params (OldId, NewId)
  IDONORSVC_CLAIMEDSALES_COUNT = 'IDonorService.GetClaimedSales'; //(const DonorId: Integer): Integer;


  IDONORSVC_GET_DONORNAME = 'IDonorService.GetDonorName'; // (const AId: String)

  IDONORSVC_DONORS_BY_CONSID = 'IDonorService.GetConsIdDonors'; // (const AConsId: Integer): TConsIdDonorList;
  IDONORSVC_OPEN_NOTIFICATIONS = 'IDonorService.GetOpenNotifications';

  ISALESSVC_REFUND_TOTALS = 'ISalesService.RefundTotals';
  // (const ADonorId: Integer; const ANotificationId: Integer): TRefundTotals;
  ISALESSVC_REFUND_CREATE = 'ISalesService.ProcessRefund';
  // (const ADonorId: Integer; const ANotificationId: Integer): Double;
  ISALESSVC_REFUND_FINALISE = 'ISalesService.FinaliseRefund'; // (const ARefundId: Integer): Boolean;

  ISALESSVC_UPDATE_DAILY_TAKINGS = 'ISalesService.UpdateDailyTakings';
  // (const ABatchId: Integer; const ACards, ACash: Currency);
  ISALESSVC_UPDATE_SHEET_COUNT = 'ISalesService.UpdateSheetCount'; // (const ABatchId, ASheetCount: Integer);
  ISALESSVC_UPDATE_SHEET_AMOUNT = 'ISalesService.UpdateSheetAmount';
  // (const ASheetId: Integer; const AAmount: Currency): boolean;
  ISALESSVC_FINALISE_SALESBATCH = 'ISalesService.FinaliseSalesBatch'; // (const ABatchId: Integer): boolean;

  IJOBSSVC_RESERVE_IDS = 'IJobsService.GetDonorIds'; // ShopRef, ACount, JobVisibility, OutputOption : integer
  IJOBSVC_USERS = 'IJobsService.GetJobUsers'; // SubmittedBy, OwnedBy, CompletedBy
  IJOBSVC_GETFILE = 'IJobsService.GetFile'; // JobId returns Stream
  IJOBSVC_SCHEDULEJOB = 'IJobsService.ScheduleJob';
  // (JobClass; JobVisibility; AParams; OutputOption,RunLevel): JobId(Integer)
  IJOBSVC_SCHEDULE_FILE_JOB = 'IJobsService.ScheduleJobWithFile';
  // (JobClass,JobVisibility,AParams,OutputOption,AFileName,AFile): Integer;

  ILOGINSVC_FORGOTPASSWORD = 'ILoginService.ForgottenPassword'; // (const UserName: string): string;';

  ISMXUTILSSVC_REFLECT = 'IsmxUtilsService.Reflect';
  ISMXUTILSSVC_REPORT_ERROR = 'IsmxUtilsService.ReportError';
  // (const AMessage, AFile: string; const ALineNumber, AColNumber: Integer; const AStack, AError: String);
  ISMXUTILSSVC_CONTACT_SUPPORT = 'IsmxUtilsService.ContactSupport';
  // (const ASubject, AFrom, AMessage, AError: String);
  ISMXUTILSSVC_LOG_ERROR = 'IsmxUtilsService.LogError';
  // (const AMessage, AFile: string; const ALineNumber,AColNumber: Integer; const AStack: string; const ACount: Integer);

  USERLEVEL_NONE = 0;
  USERLEVEL_READONLY = 1;
  USERLEVEL_SHOPUSER = 2;
  USERLEVEL_ADMINUSER = 3;
  USERLEVEL_ADMINMANAGER = 4;
  USERLEVEL_SUPERUSER = 5;
  USERLEVEL_SYSUSER = 6;

  NAME_TITLES = 'Capt,Cllr,Col,Dame,Dr,Hon,Lord,Lady,Lt,Miss,Mjr,Mr,Mrs,Ms,Mstr,Mx,Past,Prof,Rev,Sgt,Sir';

implementation

uses
  System.DateUtils,
  System.StrUtils,
  System.Rtti,
  WebLib.Controls;

const
MIN_PHONE_LEN = 8;
MAX_PHONE_LEN = 18;

{ TGAHelper }

class function TSysHelper.JobActive(const AStatus: string): Boolean;
begin
  result := (AStatus <> 'Complete') and (AStatus <> 'Failed') and (AStatus <> 'Cancelled');
end;

class procedure TSysHelper.JsonToClientDatset(Source: JS.TJSArray; Target: TClientDataset);
begin
  Target.Rows := Source;
end;

class function TSysHelper.CapitaliseFirstLetter(const Value: string): string;
begin
  if Value = '' then
    Exit('');
  result := Value.Substring(0, 1).ToUpper + Value.Substring(1).ToLower;
end;

class function TSysHelper.CapitaliseWords(const Value: string): string;
var
  // i: Integer;
  // c, lc: Char;
  lWord: string;
  lWords: TArray<string>;
begin

  result := '';
  lWords := Value.Split(smWordDelimiters);
  for lWord in lWords do
  begin
    if lWord = '' then
      Continue;
    result := result + lWord.Substring(0, 1).ToUpper + lWord.Substring(1).ToLower + ' ';
  end;

  result := result.TrimRight;

end;

class function TSysHelper.ConCat(Value: array of string; const ADelim: string): string;
var
  i: Integer;
begin
  result := '';
  for i := 0 to Length(Value) - 1 do
  begin
    if Value[i] <> '' then
      result := result + Value[i] + ADelim;
  end;
  result := result.Substring(0, result.Length - ADelim.Length);
end;

class function TSysHelper.SecondsAsTime(const ASeconds: Int64): string;
var
  lTime: TDateTime;
begin
  lTime := IncSecond(0.0, ASeconds);
  result := FormatDateTime('hh:nn:ss', lTime);
  if lTime >= 1 then
    result := Trunc(lTime).ToString + 'days ' + result;
end;

class function TSysHelper.ShortName(const ADataset: TDataset): string;
begin
  result := ConCat([ADataset.FieldByName('FirstName').AsString, ADataset.FieldByName('LastName').AsString], ' ').Trim;
end;

class function TSysHelper.DateStrToDate(const Value: string): TDate;
var
  lDate: TArray<string>;
begin
  try
    lDate := Value.Split(['-']);
    result := EncodeDate(lDate[0].ToInteger, lDate[1].ToInteger, lDate[2].ToInteger);
  except
    result := 0;
  end;
end;

class function TSysHelper.FindLookUpValue(Source: TLookupValues; const AValue: string): string;
var
  i: Integer;
begin
  result := '';
  for i := 0 to Source.Count - 1 do
  begin
    if TLookupValueItem(Source.Items[i]).Value = AValue then
      Exit(TLookupValueItem(Source.Items[i]).DisplayText);
  end;
end;

class function TSysHelper.FormatPostCode(const Value: string): string;
var
  lLength: Integer;
begin
  result := Value.ToUpper;
  if result.IndexOf(' ') = -1 then
  begin
    lLength := result.Length;
    if lLength <= 7 then
      result := result.Substring(0, lLength - 3) + ' ' + result.Substring(lLength - 3);
  end
  else
  begin
    result := Value;
    while result.IndexOf('  ') > -1 do
      result := StringReplace(result, '  ', ' ', []);
  end;
end;

class function TSysHelper.FormattedAddress(const ADataset: TDataset; const ADelim: string): string;
begin
  result := ConCat([ADataset.FieldByName('Add1').AsString, ADataset.FieldByName('Add2').AsString,
    ADataset.FieldByName('Add3').AsString, ADataset.FieldByName('City').AsString,
    ADataset.FieldByName('PostCode').AsString], ADelim);
end;

class function TSysHelper.FullName(const ADataset: TDataset): string;
begin
  result := ConCat([ADataset.FieldByName('Title').AsString, ADataset.FieldByName('FirstName').AsString,
    ADataset.FieldByName('LastName').AsString], ' ').Trim;
end;

class function TSysHelper.IsEmailValid(const Value: string): Boolean;
  function CheckAllowed(const s: string): Boolean;
  var
    i: Integer;
  begin
    result := False;
    for i := 1 to Length(s) do
    begin
      // illegal char - no valid address
      if not(s[i] in ['a' .. 'z', 'A' .. 'Z', '0' .. '9', '_', '-', '.', '+']) then
        Exit;
    end;
    result := True;
  end;

var
  i: Integer;
  namePart, serverPart: string;
begin
  result := False;

  i := Pos('@', Value);
  if (i = 0) then
    Exit;

  if (Pos('..', Value) > 0) or (Pos('@@', Value) > 0) or (Pos('.@', Value) > 0) then
    Exit;

  if (Pos('.', Value) = 1) or (Pos('@', Value) = 1) then
    Exit;

  namePart := Copy(Value, 1, i - 1);
  serverPart := Copy(Value, i + 1, Length(Value));
  if (Length(namePart) = 0) or (Length(serverPart) < 5) then
    Exit; // too short

  i := Pos('.', serverPart);
  // must have dot and at least 3 places from end
  if (i = 0) or (i > (Length(serverPart) - 2)) then
    Exit;

  result := CheckAllowed(namePart) and CheckAllowed(serverPart);

end;

class function TSysHelper.IsId(const Value: string): Boolean;
begin
  result := IsInteger(Value);
  result := result and (Value.Length <= 7);
end;

class function TSysHelper.IsInteger(const Value: string): Boolean;
var i: Integer;
begin
  result := False;
  for i := 0 to Value.Length - 1 do
  begin
    result := (Value.Chars[i] in ['0' .. '9']);
    if not result then
      Exit;
  end;
end;

class function TSysHelper.IsNumber(const Value: string): Boolean;
var
  v: Double;
begin
  result := TryStrToFloat(Value, v);
end;

class function TSysHelper.IsPhoneNumber(const Value: string): Boolean;
var i: Integer;
begin
  result := False;
  for i := 0 to Value.Length - 1 do
  begin
    result := (Value.Chars[i] in ['0' .. '9', ' ']);
    if not result then
      Exit;
  end;

  result := (Value.Length >= MIN_PHONE_LEN)  and (Value.Length <= MAX_PHONE_LEN);

end;

class function TSysHelper.IsPostCode(const Value: string): TPostCodePart;
{
  Valid Formats
  AA9A 9AA
  A9A 9AA
  A9 9AA
  A99 9AA
  AA99 9AA
}

var
  lInwardLen: Integer;

  function _CheckInward(const AInward: string): Boolean;
  begin
    if StrToIntDef(Copy(AInward, 1, 1), -1) = -1 then
      Exit(False); // first char has to be a number
    lInwardLen := Length(AInward);
    // these characters never appear in the inward code
    if (lInwardLen > 1) and (AInward[2] in ['C', 'I', 'K', 'M', 'O', 'V', '0' .. '9']) then
      Exit(False);
    if (lInwardLen = 3) and (AInward[3] in ['C', 'I', 'K', 'M', 'O', 'V', '0' .. '9']) then
      Exit(False);
    result := True;
  end;

  function _CheckOutward(AOutward: string): Boolean;
  var
    i: byte;
  begin
    for i := 1 to Length(AOutward) do
      if ord(AOutward[i]) in [65 .. 90] then // or AOutward[i] in ['A'..'Z']
        AOutward[i] := 'A'
      else
        AOutward[i] := '9';

    if AnsiIndexStr(AOutward, ['A9', 'A99', 'AA9', 'A9A', 'AA99', 'AA9A']) = -1 then
      Exit(False);

    result := True;
  end;

var
  iSpacePos, lLen: byte;
  sInput, sInward, sOutward: string;
begin
  result := pcNotPostCode;

  if (Value = EmptyStr) then
    Exit;

  if Length(Value) > 8 then
    Exit;

  sInput := UpperCase(Value);

  iSpacePos := Pos(' ', sInput);
  if iSpacePos = 0 then
  begin
    lLen := Length(sInput);
    if (lLen = 6) then
      sInput := Copy(sInput, 1, 3) + ' ' + Copy(sInput, 4, 3)
    else if (lLen = 7) then
      sInput := Copy(sInput, 1, 4) + ' ' + Copy(sInput, 4, 3)
    else if (lLen <= 4) then
    begin
      if not _CheckOutward(sInput) then
        Exit(pcNotPostCode)
      else
        Exit(pcPartial);
    end
    else
      Exit;
  end;

  sInward := Copy(sInput, iSpacePos + 1, 3);
  sOutward := Copy(sInput, 1, iSpacePos - 1);

  if not _CheckInward(sInward) then
    Exit(pcNotPostCode);

  if not _CheckOutward(sOutward) then
    Exit(pcNotPostCode);
  // build the outward code as the patterns from the site

  if lInwardLen < 3 then
    result := TPostCodePart.pcPartial
  else
    result := pcFull;
end;

class function TSysHelper.IsRef(const Value: string): Boolean;
var
  lLenVal: Integer;
begin
  result := False;
  lLenVal := Length(Value);
  if lLenVal < 3 then
    Exit;
  result := (Upcase(Value[1]) in ['A', 'H', 'R', 'S', 'W']) and (Value[2] in ['0' .. '9']) and (Value[3] in ['0' .. '9']);
  if result and (lLenVal > 3) then // Old Donor Id
  begin
    if lLenVal > 7 then
      Exit(False);

    result := (Value[4] in ['0' .. '9']) and (Value[5] in ['0' .. '9']) and (Value[6] in ['0' .. '9']) and
      (Value[7] in ['0' .. '9']);

  end;

end;

class function TSysHelper.LastTaxYearKey: Word;
var
  D, M, Y: Word;
begin
  DecodeDate(Today, Y, M, D);
  if M = 4 then // April
  begin
    if D > 5 then
      result := Y - 1
    else
      result := Y - 2;
  end
  else if M < 4 then
  begin
    result := Y - 2;
  end
  else // M >=5
  begin
    result := Y - 1;
  end;

end;

class function TSysHelper.LoadXDataRows(Source: TXDataWebDataset): JS.TJSArray;
begin
  { TODO : Really should use a bookmark }
  result := TJSArray.new;
  Source.First;
  while not Source.Eof do
  begin
    result.push(Source.CurrentData);
    Source.Next;
  end;
  Source.First;
end;

class procedure TSysHelper.LookUpValuesLoad(Source, Target: TLookupValues; const StartIndex: Integer = 1);
var
  i: Integer;
begin
  for i := StartIndex to Source.Count - 1 do
  begin
    Target.AddPair(TLookupValueItem(Source.Items[i]).Value, TLookupValueItem(Source.Items[i]).DisplayText);
  end;

end;

class function TSysHelper.RowActionSpan(const AParentElement: TJSHTMLElement; const AFontAwesomeClass: string;
 const ATooltip: String = ''; const HorizontalFlip: Boolean = False): THTMLSpan;
var
  lFlip, lTip: string;
begin
  if HorizontalFlip then
    lFlip := ' fa-flip-horizontal'
  else
    lFlip := '';

    if AToolTip = '' then
       lTip := ''
    else
       lTip := format(' title="%s"', [ATooltip]);

  result := THTMLSpan.Create(nil);
  result.Cursor := crHandPoint;
  result.ElementPosition := epIgnore;
  result.HeightStyle := ssAuto;
  result.WidthStyle := ssAuto;
  result.ParentElement := AParentElement;
  if AFontAwesomeClass.StartsWith('<') then
    result.HTML.Text := AFontAwesomeClass
  else
    result.HTML.Text := format('<i class="%s fa-fw fa-lg%s%s"></i> ', [AFontAwesomeClass, lFlip, lTip]);
end;

class function TSysHelper.SplitOnCaps(const Value: string): string;
var
  i: Integer;
  c: Char;
begin
  if Value.Length < 4 then
    Exit(Value);

  result := Value.Chars[0];
  if Value.Length = 1 then
    Exit;
  for i := 1 to Value.Length - 1 do
  begin
    c := Value.Chars[i];
    if (c in ['A' .. 'Z']) then
      result := result + ' ' + c
    else
      result := result + c;
  end;
end;

class function TSysHelper.StringToClaimStatus(const Value: string): TLocalClaimStatus;
begin
  result := TRttiEnumerationType.GetValue<TLocalClaimStatus>('lcs' + Value);
end;

class function TSysHelper.TaxYearDescriptor(const TaxYearKey: Word): string;
begin
  result := '06/04/' + TaxYearKey.ToString + ' - 05/04/' + (TaxYearKey + 1).ToString;
end;

class function TSysHelper.TaxYearStr(const Value: Integer): string;
begin
  if (Value < 2015) or (Value > 9999) then
    Exit('')
  else
    result := Value.ToString + '-' + (Value + 1).ToString.Substring(2);
end;

class procedure TSysHelper.XDataToClientDataset(Source: TXDataWebDataset; Target: TClientDataset);
var
  ARows: JS.TJSArray;
begin
  ARows := LoadXDataRows(Source);
  JsonToClientDatset(ARows, Target);
end;

end.
