unit ReportParamsForm;

interface

uses
  System.SysUtils,
  System.Classes,
  DB,
  JS,
  jsdelphisystem,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseCoreForm,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.ExtCtrls,
  smx.Reports.Types,
  WEBLib.WebCtrls;

type

  TReportParamsPopup = class(TCoreWebForm)
    ParamsOKButton: TButton;
    ParamsCancelButton: TButton;
    ParamsContainer: TPanel;
    ReportOutputFormat: TComboBox;
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure ParamsCancelButtonClick(Sender: TObject);
    procedure ParamsOKButtonClick(Sender: TObject);
  private
    FParams: JS.TJSArray;
    FControls: TStrings;
    FLocalValues: TStrings;
    FShopList: JS.TJSArray;
    FRegionList: JS.TJSArray;

    procedure SetParams(const Value: JS.TJSArray);
    procedure LoadParams(const Value: JS.TJSArray);

    procedure CreateReportControl(AParam: TJSObject; const Idx: Integer);
    function GetLocalType(const Value: string; lParent: TWinControl; const AIndex: Integer): TWinControl;
    function GetLocalValue(const ALocalName, AParamName: string; const AParamType: TFieldType;
      const AControlIndex: Integer): JSValue;
    function GetParamValues: string;
    procedure SetReportTitle(const Value: String);
    function GetShowFormatOption: Boolean;
    procedure SetShowFormatOption(const Value: Boolean);
    function GetOutputFormatOption: string;
    { Private declarations }
  public
    { Public declarations }
    property ReportTitle: String write SetReportTitle;
    property Params: JS.TJSArray write SetParams;
    property ParamValues: string read GetParamValues;
    property ShopList: JS.TJSArray read FShopList write FShopList;
    property RegionList: JS.TJSArray read FRegionList write FRegionList;
    property ShowFormatOption: Boolean read GetShowFormatOption write SetShowFormatOption;
    property OutputFormatOption: string read GetOutputFormatOption;
  protected procedure LoadDFMValues; override; end;

var
  ReportParamsPopup: TReportParamsPopup;

implementation

{$R *.dfm}

uses
  RTTI,
  XData.Web.Client,
  MainDataModule,
  AppUtils,
  smx.Reports.Support, App.Helper;

const
  Operator_Text: array [TParamOperator] of string = ('Equals', 'is Greater Than', 'is Greater Than Or Equal To',
    'is Less Than', 'is Less Than Or Equal To', 'is Not Equal To', 'Starts With');

procedure TReportParamsPopup.WebFormDestroy(Sender: TObject);
begin
  FControls.Free;
  FLocalValues.Free;
  inherited;
end;

procedure TReportParamsPopup.WebFormCreate(Sender: TObject);
begin
  inherited;
  FControls := TStringList.Create;
  FLocalValues := TStringList.Create;
  Self.Border := fbDialogSizeable;
end;

procedure TReportParamsPopup.CreateReportControl(AParam: TJSObject; const Idx: Integer);
var
  lType: string;
  lParamType: TFieldType;
  lOperator: TParamOperator;
  lDef, lQualifier: Integer;
  lDefault: TParamDefault;
  lPanel: TPanel;
  lLabel: THTMLDiv;
  lControl: TWinControl;
  lIdx: string;
  lDate: TDateTime;
  lLabelText: string;
  lLocal: Boolean;
begin

  lIdx := Idx.ToString;

  lOperator := TRttiEnumerationType.GetValue<TParamOperator>(JS.ToString(AParam['ParamOperator']));

  lPanel := TPanel.Create('param_panel_' + lIdx);
  lPanel.ElementClassName := 'ParamPanel';

  lPanel.Parent := ParamsContainer;
  lPanel.Tag := Idx;

  lLabel := THTMLDiv.Create('param_label_' + lIdx);
  lLabel.Parent := lPanel;
  lLabel.ElementClassName := 'ParamLabel';
  lLabelText := JS.ToString(AParam['DisplayName']);
  if lLabelText = '' then
    lLabelText := '<b>' + JS.ToString(AParam['ParamName']) + '</b> ' + Operator_Text[lOperator];

  lLabel.HTML.Add(lLabelText);

  lType := JS.ToString(AParam['LocalType']);

  lLocal := (lType <> '') and (lType <> 'NONE');

  if lLocal then
  begin
    lControl := GetLocalType(lType, lPanel, Idx);
  end
  else
  begin
    lType := JS.ToString(AParam['ParamType']);

    if lType = 'ftCurrency' then
      lType := 'ftFloat';

    lParamType := TRttiEnumerationType.GetValue<TFieldType>(lType);
    case lParamType of
      ftString, ftWideString:
        begin
          lControl := TEdit.Create('param_control_' + lIdx);
        end;
      ftInteger:
        begin
          lControl := TEdit.Create('param_control_' + lIdx);
          TEdit(lControl).EditType := weNumeric;
        end;
      ftFloat:
        begin
          lControl := TEdit.Create('param_control_' + lIdx);
          TEdit(lControl).EditType := weFloat;
        end;
      ftDate:
        begin
          lDef := JS.ToInteger(AParam['ParamDefault']);
          lDefault := TParamDefault(lDef);
          lControl := TDateTimePicker.Create('param_control_' + lIdx);
          TDateTimePicker(lControl).Kind := TDateTimeKind.dtkDate;

          if lDefault <> pdNoDefault then
          begin
            lQualifier := JS.toInteger(AParam['DefaultQualifier']);
            lDate := TReportSupport.GetStandardDate(lDefault, lQualifier);
            TDateTimePicker(lControl).Date := lDate;
          end;
        end;
      ftTime:
        begin
          lControl := TDateTimePicker.Create('param_control_' + lIdx);
          TDateTimePicker(lControl).Kind := TDateTimeKind.dtkTime;
        end;
      ftDateTime:
        begin
          lDefault := TRttiEnumerationType.GetValue<TParamDefault>(JS.ToString(AParam['ParamDefault']));
          lControl := TDateTimePicker.Create('param_control_' + lIdx);
          TDateTimePicker(lControl).Kind := TDateTimeKind.dtkDate;

          if lDefault <> pdNoDefault then
          begin
          lQualifier := JS.toInteger(AParam['DefaultQualifier']);
            lDate := TReportSupport.GetStandardDate(lDefault, lQualifier);
            TDateTimePicker(lControl).Date := lDate;
          end;
        end;
    end;
  end;

  lControl.Parent := lPanel;

  if (lParamType in [ftString, ftWideString, ftInteger, ftFloat]) then
  begin
    TCustomEdit(lControl).ElementPosition := epIgnore;
    TCustomEdit(lControl).HeightStyle := ssAuto;
    TCustomEdit(lControl).WidthStyle := ssAuto;
    if TCustomEdit(lControl).ElementClassName = '' then
      TCustomEdit(lControl).ElementClassName := 'form-control';

  end
  else if (lParamType in [ftDate, ftTime, ftDateTime]) then
  begin
    TCustomControl(lControl).ElementPosition := epIgnore;

    TCustomControl(lControl).HeightStyle := ssAuto;
    TCustomControl(lControl).WidthStyle := ssAuto;

    if TCustomControl(lControl).ElementClassName = '' then
      TCustomControl(lControl).ElementClassName := 'form-control';

  end;

  FControls.AddObject(JS.ToString(AParam['ParamName']), lControl);
  if Idx = 0 then
    Self.ActiveControl := lControl;

end;

function TReportParamsPopup.GetLocalType(const Value: string; lParent: TWinControl; const AIndex: Integer): TWinControl;
var
  lValueArray: JS.TJSArray;
  O: TJSObject;
  I: Integer;
  S: string;
begin
  if (Value = 'SHOPREF') or (Value = 'REGIONREF') then
  begin
    Result := TLookupComboBox.Create('param_control_' + AIndex.ToString);
    // Result.Parent := lParent;
    TComboBox(Result).ElementClassName := 'form-select form-control';

    if Value = 'SHOPREF' then
      lValueArray := FShopList
    else
      lValueArray := FRegionList;

    for I := 0 to lValueArray.Length - 1 do
    begin
      O := JS.toObject(lValueArray[I]);
      S := JS.ToString(O['Status']);
      if S <> '' then
        S := JS.ToString(O['Name']) + ' [' + S + ']'
      else
        S := JS.ToString(O['Name']);
      TLookupComboBox(Result).LookupValues.AddPair(JS.ToString(O['Ref']), S)
    end;
  end
  else if (Value = 'CLAIMSTATUS') then
  begin
    Result := TComboBox.Create('param_control_' + AIndex.ToString);
    TComboBox(Result).ElementClassName := 'form-select form-control';
    Result.Parent := lParent;
    TComboBox(Result).Items.Add('OK');
    TComboBox(Result).Items.Add('Awaiting Paperwork');
    TComboBox(Result).Items.Add('Deceased');
    TComboBox(Result).Items.Add('Donor Details Incomplete');
    TComboBox(Result).Items.Add('No Longer Pays Tax');
    TComboBox(Result).Items.Add('No Valid Address Given');
    TComboBox(Result).Items.Add('Requested Refund');
    TComboBox(Result).Items.Add('Other');
  end;

end;

function TReportParamsPopup.GetLocalValue(const ALocalName, AParamName: string; const AParamType: TFieldType;
  const AControlIndex: Integer): JSValue;
var
  lSelected: string;
begin

  if (ALocalName = 'CLAIMSTATUS') then
  begin
    lSelected := TComboBox(FControls.Objects[AControlIndex]).Text;
    Result := lSelected.Replace(' ', '', [rfReplaceAll]);
    Exit;
  end;

  // if ALocalName = 'SHOPREF' then
  // lValueArray := FShopList
  // else
  // lValueArray := FRegionList;

  if (ALocalName = 'SHOPREF') or (ALocalName = 'REGIONREF') then
  begin
    Result := TLookupComboBox(FControls.Objects[AControlIndex]).Value;
  end;

  // for I := 0 to lValueArray.Length - 1 do
  // begin
  // O := JS.ToObject(lValueArray[I]);
  // lName := JS.ToString(O['Name']);
  // if lName = lSelected then
  // begin
  // Result := O['Ref'];
  // break;
  // end;
  // end;

end;

function TReportParamsPopup.GetOutputFormatOption: string;
begin
  Result := ReportOutputFormat.Text;
end;

function TReportParamsPopup.GetParamValues: string;
var
  lType, lLocalType, lName, lVal: string;
  lParamType: TFieldType;
  Idx, I: Integer;
  AParam, O: JS.TJSObject;
  lResult: JS.TJSArray;
begin
  Result := '';

  lResult := JS.TJSArray.new(FParams.Length);

  for I := 0 to FParams.Length - 1 do
  begin
    AParam := JS.toObject(FParams[I]);
    lLocalType := JS.ToString(AParam['LocalType']);
    lName := JS.ToString(AParam['ParamName']);
    Idx := FControls.IndexOf(lName);
    lType := JS.ToString(AParam['ParamType']);
    if lType = 'ftCurrency' then
      lType := 'ftFloat';
    lParamType := TRttiEnumerationType.GetValue<TFieldType>(lType);
    O := JS.TJSObject.new;
    O['Key'] := lName;

    if (lLocalType <> '') and (lLocalType <> 'NONE') then
    begin
      O['Value'] := GetLocalValue(lName, lLocalType, lParamType, Idx);
      lResult[I] := O;
    end
    else
    begin
      case lParamType of
        ftString, ftWideString:
          begin
            lVal := TEdit(FControls.Objects[Idx]).Text;
            O['Value'] := lVal;
          end;
        ftInteger:
          begin
            lVal := TEdit(FControls.Objects[Idx]).Text;
            O['Value'] := lVal.ToInteger;
          end;
        ftFloat:
          begin
            lVal := TEdit(FControls.Objects[Idx]).Text;
            O['Value'] := lVal.ToDouble;
          end;
        ftDate:
          O['Value'] := TDateTimePicker(FControls.Objects[Idx]).Date;
        ftTime:
          O['Value'] := TDateTimePicker(FControls.Objects[Idx]).Time;
        ftDateTime:
          O['Value'] := TDateTimePicker(FControls.Objects[Idx]).DateTime;
      end;

      lResult[I] := O;

    end;
  end;

  Result := TJSJSON.stringify(lResult);

end;

function TReportParamsPopup.GetShowFormatOption: Boolean;
begin
  Result := ReportOutputFormat.Visible;
end;

procedure TReportParamsPopup.LoadParams(const Value: JS.TJSArray);
var
  I: Integer;
begin
  FParams := Value;

  for I := 0 to FParams.Length - 1 do
  begin
    CreateReportControl(JS.toObject(FParams[I]), I);
  end;
end;

procedure TReportParamsPopup.ParamsCancelButtonClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

procedure TReportParamsPopup.ParamsOKButtonClick(Sender: TObject);
begin
  ModalResult := mrOK;
end;

procedure TReportParamsPopup.SetParams(const Value: JS.TJSArray);
begin
  LoadParams(Value);
end;

procedure TReportParamsPopup.SetReportTitle(const Value: String);
begin
  THTMLHelper.writeHTML('ReportTitle', Value);
end;

procedure TReportParamsPopup.SetShowFormatOption(const Value: Boolean);
begin
  ReportOutputFormat.Visible := Value;
end;

procedure TReportParamsPopup.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ParamsOKButton := TButton.Create('ParamsOKButton');
  ParamsCancelButton := TButton.Create('ParamsCancelButton');
  ParamsContainer := TPanel.Create('ParamsContainer');
  ReportOutputFormat := TComboBox.Create('ReportOutputFormat');

  ParamsOKButton.BeforeLoadDFMValues;
  ParamsCancelButton.BeforeLoadDFMValues;
  ParamsContainer.BeforeLoadDFMValues;
  ReportOutputFormat.BeforeLoadDFMValues;
  try
    Width := 675;
    Height := 458;
    ElementClassName := 'PopUpForm';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    ParamsOKButton.SetParentComponent(Self);
    ParamsOKButton.Name := 'ParamsOKButton';
    ParamsOKButton.Left := 400;
    ParamsOKButton.Top := 384;
    ParamsOKButton.Width := 96;
    ParamsOKButton.Height := 25;
    ParamsOKButton.Caption := 'OK';
    ParamsOKButton.ChildOrder := 1;
    ParamsOKButton.ElementClassName := 'btn btn-light';
    ParamsOKButton.ElementFont := efCSS;
    ParamsOKButton.ElementPosition := epIgnore;
    ParamsOKButton.HeightStyle := ssAuto;
    ParamsOKButton.HeightPercent := 100.000000000000000000;
    ParamsOKButton.WidthStyle := ssAuto;
    ParamsOKButton.WidthPercent := 100.000000000000000000;
    SetEvent(ParamsOKButton, Self, 'OnClick', 'ParamsOKButtonClick');
    ParamsCancelButton.SetParentComponent(Self);
    ParamsCancelButton.Name := 'ParamsCancelButton';
    ParamsCancelButton.Left := 502;
    ParamsCancelButton.Top := 384;
    ParamsCancelButton.Width := 96;
    ParamsCancelButton.Height := 25;
    ParamsCancelButton.Caption := 'Cancel';
    ParamsCancelButton.ChildOrder := 2;
    ParamsCancelButton.ElementClassName := 'btn btn-light';
    ParamsCancelButton.ElementFont := efCSS;
    ParamsCancelButton.ElementPosition := epIgnore;
    ParamsCancelButton.HeightStyle := ssAuto;
    ParamsCancelButton.HeightPercent := 100.000000000000000000;
    ParamsCancelButton.WidthStyle := ssAuto;
    ParamsCancelButton.WidthPercent := 100.000000000000000000;
    SetEvent(ParamsCancelButton, Self, 'OnClick', 'ParamsCancelButtonClick');
    ParamsContainer.SetParentComponent(Self);
    ParamsContainer.Name := 'ParamsContainer';
    ParamsContainer.Left := 24;
    ParamsContainer.Top := 24;
    ParamsContainer.Width := 574;
    ParamsContainer.Height := 354;
    ParamsContainer.ElementClassName := 'card';
    ParamsContainer.HeightStyle := ssAuto;
    ParamsContainer.WidthStyle := ssAuto;
    ParamsContainer.ChildOrder := 3;
    ParamsContainer.ElementFont := efCSS;
    ParamsContainer.ElementPosition := epIgnore;
    ReportOutputFormat.SetParentComponent(Self);
    ReportOutputFormat.Name := 'ReportOutputFormat';
    ReportOutputFormat.Left := 32;
    ReportOutputFormat.Top := 392;
    ReportOutputFormat.Width := 145;
    ReportOutputFormat.Height := 23;
    ReportOutputFormat.ElementClassName := 'form-select';
    ReportOutputFormat.ElementFont := efCSS;
    ReportOutputFormat.HeightStyle := ssAuto;
    ReportOutputFormat.HeightPercent := 100.000000000000000000;
    ReportOutputFormat.Text := 'Excel';
    ReportOutputFormat.Visible := False;
    ReportOutputFormat.WidthPercent := 100.000000000000000000;
    ReportOutputFormat.ItemIndex := 0;
    ReportOutputFormat.Items.BeginUpdate;
    try
      ReportOutputFormat.Items.Clear;
      ReportOutputFormat.Items.Add('Excel');
      ReportOutputFormat.Items.Add('CSV');
    finally
      ReportOutputFormat.Items.EndUpdate;
    end;
  finally
    ParamsOKButton.AfterLoadDFMValues;
    ParamsCancelButton.AfterLoadDFMValues;
    ParamsContainer.AfterLoadDFMValues;
    ReportOutputFormat.AfterLoadDFMValues;
  end;
end;

end.
