unit DailySalesForm;

interface

{
  Submission Process (handling the vagaries of XData returns

  New Submission

  SalesSheet.Edit creates a new SalesSheet Record
  <data entry>
  Form Submitted

  SaveButton.Click

  - check totals (different to previous in batch, then prompt)

  SalesSheet record updated
  - SalesSheet.Number
  - SalesSheet.Count (as entered by user)
  - if SalesSheetNumber > 1
  - BatchId
  - (credit cards, cash and Total Banked will be cleared on the server)
  - SalesSheet.Post Record

  SalesSheet.AfterApplyUpdates
  - Get SalesSheet.Id
  - if SalesSheet.Number = 1
  - Fetch SalesSheet by Id, to retrieve batch number (only need to do this if Count > 1, but worth doing as the user
  might have entered 1 by mistake and we'll pick it up on the next entry

  SalesSheet.AfterOpen
  - call UpdateSales

  Sales.AfterUpdate
  - Close Sales
  - Close SalesSheet

  12-01-2023 RPW if on sheet 1 then leaving the sales grid moves to Cash Amount, else to Sales Total
  --
}

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  WEBLib.StdCtrls,
  WEBLib.DB,
  System.Generics.Collections,
  WEBLib.WebCtrls,
  XData.Web.Client,
  WEBLib.JQCtrls,
  WEBLib.Actions,
  BaseCoreForm,
  WEBLib.Toast,
  Vcl.Imaging.GIFImg;

type
  TDonorIdState = (disUnused, disValidating, disInvalid, disValid, disVoid);

  TDonorIdStateSet = set of TDonorIdState;

  TSaleState = (ssUnused, ssValidating, ssIncomplete, ssComplete);

  TEditBlock = class
  private
    FDonorIdState: TDonorIdState;
    function GetIsCompleteOrUnused: Boolean;
    function GetSaleState: TSaleState;
    function GetSaleAmount: Currency;
    procedure WriteDonorIdState(const Value: TDonorIdState);
  public
    ControlIndex: Integer;
    FormattedIndex: string;
    DonorIdControl: TEdit;
    AmountControl: TEdit;
    NameControl: TLabel;
    DonorId: Integer;
    OldDonorId: string;
    ValidatedId: string;
    constructor Create(const AControlIndex: Integer; ADonorId: TEdit; AAmount: TEdit; ANameControl: TLabel);
    procedure Clear;
    property DonorState: TDonorIdState read FDonorIdState write WriteDonorIdState;
    property IsCompleteOrUnused: Boolean read GetIsCompleteOrUnused;
    property SaleState: TSaleState read GetSaleState;
    property SaleAmount: Currency read GetSaleAmount;
  end;

  // TSalesItem = record
  // DonorId: Integer;
  // Amount: Currency;
  // SaleState: TSaleState;
  // end;

  TSheetHistory = class
    ShopRef: string;
    Salesdate: TDate;
    BatchId: Integer;
    SheetCount: Integer;
    SheetId: Integer;
    SheetNumber: Integer;
    Cash: Double;
    Cards: Double;
    Amount: Double;
    Count: Integer;
    CompletedBy: string;
    // SheetHash: string;
    // SalesItems: array [0 .. 15] of TSalesItem;
  end;

  THistoryList = class(TObjectList<TSheetHistory>)
  private
  public
    procedure UpdateCashAndCards(const ABatchId: Integer; const ACash, ACards: Currency);
    procedure UpdateSheetCount(const ABatchId: Integer; const ASheetCount: Integer);
  end;

  TDailySales = class(TCoreWebForm)
    SalesSheet: TXDataWebDataSet;
    SalesSheetId: TIntegerField;
    SalesSheetShopRef: TStringField;
    SalesSheetSaleDate: TDateField;
    SalesSheetCreditCards: TFloatField;
    SalesSheetCashAndCheques: TFloatField;
    SalesSheetTotalBanked: TFloatField;
    SalesSheetAddedBy: TIntegerField;
    SalesSheetDateAdded: TDateTimeField;
    SalesSheetLastUpdatedBy: TIntegerField;
    SalesSheetLastUpdated: TDateTimeField;
    Sales: TXDataWebDataSet;
    SalesId: TIntegerField;
    SalesDonorId: TIntegerField;
    SalesShopRef: TStringField;
    SalesSaleDate: TDateField;
    SalesAmount: TFloatField;
    SalesCommission: TFloatField;
    SalesNetAmount: TFloatField;
    SalesClaimStatus: TStringField;
    SalesClaimId: TIntegerField;
    SalesClaimRef: TStringField;
    SalesClaimDate: TDateField;
    SalesClaimStatusChanged: TDateField;
    SalesNotificationId: TIntegerField;
    SalesRefundDate: TDateField;
    SalesRefundId: TIntegerField;
    SalesAddedBy: TIntegerField;
    SalesDateAdded: TDateTimeField;
    SalesLastUpdatedBy: TIntegerField;
    SalesLastUpdated: TDateTimeField;
    ShopsLookUp: TXDataWebDataSet;
    ShopsLookUpRef: TStringField;
    ShopsLookUpName: TStringField;
    SalesSheetSource: TDataSource;
    SaleDate: TDBDateTimePicker;
    CashAmount: TDBEdit;
    CreditCards: TDBEdit;
    SalesTotal: THTMLSpan;
    WebClient: TXDataWebClient;
    SaveButton: TButton;
    SalesSheetCompletedBy: TStringField;
    CompletedBy: TDBEdit;
    SalesSheetSheetNumber: TIntegerField;
    SalesSheetSheetCount: TIntegerField;
    SalesSheetId2: TIntegerField;
    SheetNumber: TLabel;
    SalesPageNum: TIntegerField;
    SalesSheetBatchId: TIntegerField;
    ShopRef: TDBEdit;
    ShopName: TLabel;
    SalesTaxYear: TIntegerField;
    DailySalesDlg: TMessageDlg;
    SalesSheetTotalSales: TFloatField;
    DailySalesWait: TWaitMessage;
    SheetCount: TSpinEdit;
    SalesOldRef: TStringField;
    SheetTotalAmount: TEdit;
    SalesEnteredId: TStringField;
    SalesNotificationDate: TDateField;
    SalesNotificationSent: TBooleanField;
    SalesSalesTaxYear: TIntegerField;
    SalesSalesYear: TIntegerField;
    SalesGiftAidValue: TFloatField;
    SalesCompanyYear: TIntegerField;
    procedure WebFormDestroy(Sender: TObject);
    procedure CashAmountExit(Sender: TObject);
    procedure CashAmountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure CompletedByKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure CreditCardsKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure SaleDateExit(Sender: TObject);
    procedure SaleDateKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure SaleDateKeyPress(Sender: TObject; var Key: Char);
    procedure WebFormCreate(Sender: TObject);
    procedure SalesAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure SalesSheetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure SalesSheetAfterPost(Dataset: TDataSet);
    procedure SaveButtonClick(Sender: TObject);
    procedure SheetCountExit(Sender: TObject);
    procedure SheetCountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure SheetTotalAmountExit(Sender: TObject);
    procedure SheetTotalAmountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure ShopRefExit(Sender: TObject);
    procedure ShopRefKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure ShopsLookUpAfterOpen(Dataset: TDataSet);
  private
    { Private declarations }
    FHistory: THistoryList;
    FEditBlocks: TObjectList<TEditBlock>;
    FSaleDateValid: Boolean;
    FShopRefValid: Boolean;
    FMinDate: TDate;
    FSheetCount: Integer;
    FSheetNumber: Integer;
    FBatchId: Integer;
    FSheetsSubmitted: Integer;
    FSheetValue: Double;
    FEntryCount: Integer;

    FLastSheetIndex: Integer;

    procedure UpdateSales(const ASalesSheetId: Integer);
    procedure CreateEditFields;
    procedure DonorIDExit(Sender: TObject);
    procedure AmountExit(Sender: TObject);

    procedure DonorIdKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure AmountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);

    procedure NameControlClick(Sender: TObject);

    [async]
    procedure ValidateId(AControl: TEdit); async;
    procedure ValidateDate;
    function ShowSalesValue(const AConfirmed: Boolean): Currency;
    function CalcSalesValue(const AConfirmed: Boolean): Currency;
    function CalcBankedValue: Currency;
    function CanSubmit: Boolean;

    [async]
    function CheckBankedValues: Boolean; async;
    [async]
    function CheckCreditCardOK: Boolean; async;
    [async]
    function CheckCashOK: Boolean; async;

    [async]
    function CheckIfLastBatch: Boolean; async;
    [async]
    function CheckIfNewBatch: Boolean; async;
    [async]
    function CheckIfRepeated: Boolean; async;

    procedure ClearSalesSheet(const NewBatch: Boolean);
    procedure MoveOutOfSalesGrid;

    [async]
    procedure ProcessData; async;
    procedure ValidateSheet;
    procedure SetThisSheetNumber(const Value: Integer);
    procedure SetSheetCount(const Value: Integer);
  protected
    property ThisSheetNumber: Integer read FSheetNumber write SetThisSheetNumber;
    property ThisSheetCount: Integer read FSheetCount write SetSheetCount;
  public
    { Public declarations }
    destructor Destroy; override;
  protected procedure LoadDFMValues; override; end;

var
  DailySales: TDailySales;

implementation

uses
  System.Math,
  System.DateUtils,
  System.UITypes,
  MainDataModule,
  AppUtils,
  App.Helper,
  Auth.Service,
  InputQueryModule;

{$R *.dfm}

const
  START_TAB_INDEX = 5;
  MARK_AS_VOID_HINT = 'Click to mark as void';
  CAN_MARK_AS_VOID = 'Id not allocated';

  { TEditBlock }

procedure TEditBlock.Clear;
begin
  DonorIdControl.Clear;
  AmountControl.Clear;
  NameControl.Caption := ControlIndex.ToString;
  THTMLHelper.removeClass(NameControl.ElementID, 'is-clickable');
  NameControl.Hint := '';
  FDonorIdState := disUnused;
  DonorId := 0;
  OldDonorId := '';
  ValidatedId := '';
end;

constructor TEditBlock.Create(const AControlIndex: Integer; ADonorId, AAmount: TEdit; ANameControl: TLabel);
begin
  ControlIndex := AControlIndex;
  FormattedIndex := FormatFloat('00', AControlIndex);
  DonorIdControl := ADonorId;
  AmountControl := AAmount;
  NameControl := ANameControl;
  FDonorIdState := disUnused;

end;

function TEditBlock.GetIsCompleteOrUnused: Boolean;
var
  lSaleState: TSaleState;
begin
  lSaleState := SaleState;
  Result := (lSaleState in [ssUnused, ssComplete]);
end;

// TSaleState = (ssUnused, ssValidating, ssIncomplete, ssComplete);
function TEditBlock.GetSaleAmount: Currency;
begin
  Result := RoundTo(StrToCurrDef(AmountControl.Text, 0), -2);
end;

function TEditBlock.GetSaleState: TSaleState;
begin
  case FDonorIdState of
    disUnused:
      begin
        if SaleAmount = 0 then
          Result := ssUnused
        else
          Result := ssIncomplete;
      end;
    disValidating:
      Result := ssValidating;
    disInvalid:
      Result := ssIncomplete;
    disValid, disVoid:
      begin
        if SaleAmount = 0 then
          Result := ssIncomplete
        else
          Result := ssComplete;
      end;
  end;
end;

procedure TEditBlock.WriteDonorIdState(const Value: TDonorIdState);
begin
  FDonorIdState := Value;
end;

{ TDailySales }

procedure TDailySales.WebFormCreate(Sender: TObject);
begin
  ShopsLookUp.Load;
  ShopName.Caption := 'Shop Ref';
  FSheetsSubmitted := 0;
  FLastSheetIndex := -1;

  if AuthService.IsAdministrator then
    FMinDate := IncMonth(Today, -12)
  else
    FMinDate := IncMonth(Today, -6);

  FHistory := THistoryList.Create(True);

  CreateEditFields;

end;

procedure TDailySales.AmountExit(Sender: TObject);
var
  LControl: TEdit;
  LValue: Double;
begin
  LControl := TEdit(Sender);

  LValue := StrToFloatDef(LControl.Text, -1);
  if LValue > -1 then
    LControl.Text := FormatFloat('#0.00', RoundTo(LValue, -2));

  ShowSalesValue(False);
  ValidateSheet;
end;

procedure TDailySales.DonorIdKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
var
  idx: Integer;
begin
  idx := TEdit(Sender).Tag;
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
    begin
      if idx = 0 then
        SaleDate.SetFocus
      else
        FEditBlocks[idx - 1].AmountControl.SetFocus;
    end
    else
    begin
      if (idx > 0) and (FEditBlocks[idx].DonorIdControl.Text = '') and (FEditBlocks[idx - 1].DonorIdControl.Text = '')
        and (FEditBlocks[idx - 1].AmountControl.Text = '') then
        MoveOutOfSalesGrid
      else
        FEditBlocks[idx].AmountControl.SetFocus;
    end;
  end
  else if (Key = key_up) then
  begin
    case idx of
      0:
        ShopRef.SetFocus;
      1:
        SheetCount.SetFocus;
      2, 3:
        SaleDate.SetFocus;
    else
      FEditBlocks[idx - 1].AmountControl.SetFocus;
    end;
  end
  else if (Key = key_left) then
  begin
    if idx > 0 then
      FEditBlocks[idx - 1].DonorIdControl.SetFocus
    else
      SaleDate.SetFocus;
  end
  else if (Key = key_right) then
  begin
    if idx = 15 then
      CashAmount.SetFocus
    else
      FEditBlocks[idx + 1].DonorIdControl.SetFocus;
  end
  else if (Key = key_down) then
  begin
    FEditBlocks[idx].AmountControl.SetFocus;
  end;
end;

procedure TDailySales.AmountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
var
  idx: Integer;
begin
  idx := TEdit(Sender).Tag;
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
      FEditBlocks[idx].DonorIdControl.SetFocus
    else if (idx = 15) then
      MoveOutOfSalesGrid
      // SheetTotalAmount.SetFocus
      // else if (FEditBlocks[idx].DonorIdControl.Text = '') and (FEditBlocks[idx].AmountControl.Text = '') then
      // MoveOutOfSalesGrid
    else
      FEditBlocks[idx + 1].DonorIdControl.SetFocus;
  end
  // else if (Key = key_tab) then
  // begin
  // if (FEditBlocks[idx].DonorIdControl.Text = '') and (FEditBlocks[idx].AmountControl.Text = '') then
  // begin
  // CashAmount.SetFocus;
  // Key := 0;
  // end;
  // end

  else if (Key = key_up) then
    FEditBlocks[idx].DonorIdControl.SetFocus
  else if (Key = key_left) then
  begin
    if idx > 0 then
      FEditBlocks[idx - 1].AmountControl.SetFocus
    else
      SaleDate.SetFocus;
  end
  else if (Key = key_right) then
  begin
    if idx = 15 then
      CashAmount.SetFocus
    else
      FEditBlocks[idx + 1].AmountControl.SetFocus;
  end
  else if (Key = key_down) then
  begin
    if idx >= 12 then
      CashAmount.SetFocus
    else
      FEditBlocks[idx + 4].DonorIdControl.SetFocus;
  end;

end;

function TDailySales.CalcBankedValue: Currency;
begin
  Result := SalesSheetCashAndCheques.Value + SalesSheetCreditCards.Value;
end;

function TDailySales.CalcSalesValue(const AConfirmed: Boolean): Currency;
const
  cStates: array [Boolean] of TDonorIdStateSet = ([disValidating, disValid, disVoid], [disValid, disVoid]);
var
  I, lCount: Integer;
begin
  Result := 0.00;
  lCount := 0;

  for I := 0 to 15 do
  begin
    if (FEditBlocks[I].DonorState in cStates[AConfirmed]) then
    begin
      Result := Result + FEditBlocks[I].SaleAmount;
      lCount := lCount + 1;
    end;
  end;

  if AConfirmed then
  begin
    FSheetValue := Result;
    FEntryCount := lCount;
  end;

end;

function TDailySales.ShowSalesValue(const AConfirmed: Boolean): Currency;
begin
  Result := CalcSalesValue(AConfirmed);
  SalesTotal.HTML.Text := FormatFloat('#,##0.00', Result);
end;

function TDailySales.CanSubmit: Boolean;
var
  I: Integer;
  LValue, LEnteredValue: Currency;
begin

  Result := FShopRefValid;
  if not Result then
    Exit;

  for I := 0 to 15 do
  begin
    Result := FEditBlocks[I].GetIsCompleteOrUnused;
    if not Result then
      Exit;
  end;

  LValue := ShowSalesValue(True);
  LEnteredValue := RoundTo(StrToFloatDef(SheetTotalAmount.Text, 0), -2);

  SalesSheetTotalSales.Value := LValue;

  Result := (LValue > 0); // and (CalcBankedValue >= LValue);

  Result := Result and (SaleDate.Date >= FMinDate) and (SaleDate.Date <= Today);

  Result := Result and (LEnteredValue = LValue);
end;

procedure TDailySales.CreateEditFields;

  function CreateEdit(const aNum: Integer; AType: string): TEdit;
  var
    lName: string;
  begin
    lName := AType + '_' + FormatFloat('00', aNum);
    Result := TEdit.Create(Self);
    Result.ElementID := AType + '_' + FormatFloat('00', aNum + 1);
    Result.Name := lName;
    Result.ElementFont := efCSS;
    Result.HeightStyle := ssAuto;
    Result.Text := '';
    Result.Tag := aNum;
    Result.WidthStyle := ssAuto;

    if AType = 'DonorID' then
    begin
      Result.ElementClassName := 'form-control id-control';
      Result.EditType := TEditType.weString;
      Result.CharCase := wecUpperCase;
      Result.OnExit := DonorIDExit;
      Result.OnKeyDown := DonorIdKeyDown;
      Result.TabOrder := aNum + START_TAB_INDEX;
    end
    else
    begin
      Result.ElementClassName := 'form-control';
      Result.EditType := TEditType.weFloat;
      Result.OnExit := AmountExit;
      Result.OnKeyDown := AmountKeyDown;
      Result.TabOrder := aNum + START_TAB_INDEX + 1;
    end;
  end;

  function CreateText(aNum: Integer): TLabel;
  var
    lName: string;
  begin
    lName := 'Label_' + FormatFloat('00', aNum);
    Result := TLabel.Create(Self);
    Result.Name := lName;
    Result.Tag := aNum;
    Result.ElementID := 'Label_' + FormatFloat('00', aNum + 1);
    Result.ElementFont := efCSS;
    Result.HeightStyle := ssAuto;
    Result.HTMLType := THTMLType.tLABELTAG;
    Result.WidthStyle := ssAuto;
    Result.Caption := aNum.ToString;
  end;

var
  I: Integer;
  lDonorId, lAmount: TEdit;
  lText: TLabel;
begin
  FEditBlocks := TObjectList<TEditBlock>.Create(True);
  for I := 0 to 15 do
  begin
    lDonorId := CreateEdit(I, 'DonorID');
    lAmount := CreateEdit(I, 'Amount');
    lText := CreateText(I);
    FEditBlocks.Add(TEditBlock.Create(I, lDonorId, lAmount, lText));
  end;
end;

destructor TDailySales.Destroy;
begin
  FEditBlocks.Free;
  inherited;
end;

procedure TDailySales.WebFormDestroy(Sender: TObject);
begin
  FHistory.Free;
  inherited;
end;

procedure TDailySales.CashAmountExit(Sender: TObject);
begin
  ValidateSheet;
end;

procedure TDailySales.CashAmountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if Key = 13 then
  begin
    if (ssShift in Shift) then
      FEditBlocks[15].AmountControl.SetFocus
    else
      CreditCards.SetFocus;
  end
  else if (Key = key_up) then
    FEditBlocks[12].AmountControl.SetFocus
  else if (Key = key_left) then
    FEditBlocks[15].AmountControl.SetFocus
  else if (Key = key_right) then
    CreditCards.SetFocus
  else if (Key = key_down) then
    CompletedBy.SetFocus;
end;

function TDailySales.CheckBankedValues: Boolean;
var
  mr: TModalResult;
  lMessage: string;
begin

  if FLastSheetIndex < 0 then
    Exit(False);

  if (FBatchId = 0) or ((SalesSheetCreditCards.Value = FHistory[FLastSheetIndex].Cards) and
    (SalesSheetCashAndCheques.Value = FHistory[FLastSheetIndex].Cash)) then
    Exit(False);

  lMessage := 'The banking totals do not match earlier entries for this batch.<br><br>';
  if (FLastSheetIndex > -1) and (SalesSheetCreditCards.Value <> FHistory[FLastSheetIndex].Cards) then
    lMessage := lMessage + 'Cards: ' + FormatFloat('#,##0.00 ', SalesSheetCreditCards.Value) +
      FormatFloat('(#,##0.00). ', FHistory[FLastSheetIndex].Cards);

  if (FLastSheetIndex > -1) and (SalesSheetCashAndCheques.Value <> FHistory[FLastSheetIndex].Cash) then
    lMessage := lMessage + 'Cash: ' + FormatFloat('#,##0.00 ', SalesSheetCashAndCheques.Value) +
      FormatFloat('(#,##0.00). ', FHistory[FLastSheetIndex].Cash);

  lMessage := lMessage +
    'Do you want to update the original values (Yes) or set these values to the previously entered ones (No)?';

  mr := await(TModalResult, DailySalesDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));

  Result := (mr = mrYes);

  if Result then
  begin
    FHistory[FLastSheetIndex].Cards := SalesSheetCreditCards.Value;
    FHistory[FLastSheetIndex].Cash := SalesSheetCashAndCheques.Value;
    await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(ISALESSVC_UPDATE_DAILY_TAKINGS,
      [FBatchId, FHistory[FLastSheetIndex].Cards, FHistory[FLastSheetIndex].Cash]));
  end
  else
  begin
    SalesSheetCreditCards.Value := FHistory[FLastSheetIndex].Cards;
    SalesSheetCashAndCheques.Value := FHistory[FLastSheetIndex].Cash;
  end;

end;

function TDailySales.CheckCashOK: Boolean;
var
  mr: TModalResult;
  lMessage: string;
begin
  Result := SalesSheetCashAndCheques.Value >= 0;
  if Result then
    Exit;

  lMessage := 'The cash and cheques amount entered is less that 0. Is this correct?';

  mr := await(TModalResult, DailySalesDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));

  Result := (mr = mrYes);
end;

function TDailySales.CheckCreditCardOK: Boolean;
var
  mr: TModalResult;
  lMessage: string;
begin
  Result := SalesSheetCreditCards.Value >= 0;
  if Result then
    Exit;

  lMessage := 'The credit card amount entered is less that 0. Is this correct?';

  mr := await(TModalResult, DailySalesDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));

  Result := (mr = mrYes);

end;

function TDailySales.CheckIfLastBatch: Boolean;
var
  mr: TModalResult;
  lMessage: string;
  lSheetNo: Integer;
begin

  Result := False;

  if FLastSheetIndex < 0 then
    Exit;

  if FSaleDateValid and (FSheetNumber = 1) and (FHistory[FLastSheetIndex].Salesdate = SaleDate.Date) and
    (FHistory[FLastSheetIndex].ShopRef = ShopRef.Text) then
  begin

    lSheetNo := FHistory[FLastSheetIndex].SheetNumber + 1;
    lMessage :=
      Format('The sales date and Shop Ref are the same as the last sheet you entered. Is this actually sheet number %d of that batch?',
      [lSheetNo]);

    mr := await(TModalResult, DailySalesDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));

    Result := (mr = mrYes);
  end;

end;

function TDailySales.CheckIfNewBatch: Boolean;
var
  lMessage: string;
  lNewDate, lNewShop: Boolean;
  mr: TModalResult;
  AForm: TInputQueryForm;
  lRetval: TModalResult;
begin
  Result := False;
  // This is a new batch
  if ThisSheetNumber = 1 then
    Exit;

  if FLastSheetIndex < 0 then
    Exit;

  lNewDate := (FHistory[FLastSheetIndex].Salesdate <> SaleDate.Date);
  lNewShop := (FHistory[FLastSheetIndex].ShopRef <> ShopRef.Text);

  if lNewDate or lNewShop then
  begin
    if lNewDate and lNewShop then
      lMessage :=
        Format('The date %s (%s) and Shop %s (%s) are different from the last one entered. Make this the start of a new batch?',
        [FormatDateTime('dd/mm/yyyy', SaleDate.Date), FormatDateTime('dd/mm/yyyy', FHistory[FLastSheetIndex].Salesdate),
        ShopRef.Text, FHistory[FLastSheetIndex].ShopRef])
    else if lNewDate then
      lMessage := Format('The date %s is different from the last one entered - %s. Make this the start of a new batch?',
        [FormatDateTime('dd/mm/yyyy', SaleDate.Date), FormatDateTime('dd/mm/yyyy',
        FHistory[FLastSheetIndex].Salesdate)])
    else if lNewShop then
      lMessage := Format('The Shop %s is different from the last one entered - %s. Make this the start of a new batch?',
        [ShopRef.Text, FHistory[FLastSheetIndex].ShopRef]);

    mr := await(TModalResult, DailySalesDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));

    Result := (mr = mrYes);

    if Result then
    begin
      AForm := TInputQueryForm.Create(Self);
      try
        AForm.Popup := True;
        AForm.PopupOpacity := 1;
        await(TForm, AForm.Load());
        AForm.Caption := 'New Batch';
        AForm.Prompt := 'How many sheets are in this batch?';
        AForm.EditType := weNumeric;
        AForm.InputValue := '1';
        lRetval := await(TModalResult, AForm.Execute);
        if lRetval = mrOK then
          ThisSheetCount := StrToIntDef(AForm.InputValue, 1)
        else
          ThisSheetCount := 1;
      finally
        AForm.Free;
        AForm := nil;
      end;
    end;
  end;
end;

function TDailySales.CheckIfRepeated: Boolean;
var
  lMessage: string;
  mr: TModalResult;
begin
  if FLastSheetIndex < 0 then
    Exit(False);

  Result := (FHistory[FLastSheetIndex].Amount = FSheetValue) and (FHistory[FLastSheetIndex].Count = FEntryCount);
  if Result then
  begin
    // for I := 0 to 15 do
    // begin
    // lMatch := (lSheetHistory.SalesItems[I].DonorId = FEditBlocks[I].DonorId) and
    // (lSheetHistory.SalesItems[I].Amount = FEditBlocks[I].SaleAmount);
    // if not lMatch then
    // break;
    // end;
    //
    // if not lMatch then
    // Exit;

    lMessage := 'The totals for this sheet are the same as the last sheet. Has this been accidentally re-entered?';
    mr := await(TModalResult, DailySalesDlg.ShowDialog(lMessage, WEBLib.Dialogs.mtConfirmation, [mbYes, mbNo]));
    Result := (mr = mrYes);
    if Result then
      ClearSalesSheet(False);
  end
  else
    Result := False;
end;

procedure TDailySales.ClearSalesSheet(const NewBatch: Boolean);
var
  I: Integer;
begin
  SheetTotalAmount.Text := '';

  for I := 0 to 15 do
  begin
    FEditBlocks[I].Clear;
    ControlNoValidity(FEditBlocks[I].DonorIdControl.ElementID);
  end;

  ValidateSheet;

  if NewBatch then
  begin
    ControlNoValidity('ShopRef');
    FShopRefValid := False;
    ShopName.Caption := 'Shop Ref';
    ThisSheetCount := 0;
    ShopRef.SetFocus;
  end
  else
    FEditBlocks[0].DonorIdControl.SetFocus;

end;

procedure TDailySales.CompletedByKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if (Key = key_enter) then
  begin
    if (ssShift in Shift) then
      CreditCards.SetFocus
    else
    begin
      if SaveButton.Enabled then
        SaveButton.SetFocus
      else
        CompletedBy.SetFocus;
    end;
  end
  else if (Key = key_up) then
    CashAmount.SetFocus
  else if (Key = key_left) then
    SheetTotalAmount.SetFocus
  else if (Key = key_right) or (Key = key_down) then
  begin
    if SaveButton.Enabled then
      SaveButton.SetFocus
    else
      CompletedBy.SetFocus;
  end;
end;

procedure TDailySales.CreditCardsKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if Key = 13 then
  begin
    if (ssShift in Shift) then
      CashAmount.SetFocus
    else
      SheetTotalAmount.SetFocus;
  end
  else if (Key = key_up) then
    FEditBlocks[13].AmountControl.SetFocus
  else if (Key = key_left) then
    CashAmount.SetFocus
  else if (Key = key_right) then
    SheetTotalAmount.SetFocus
  else if (Key = key_down) then
    CompletedBy.SetFocus;
end;

procedure TDailySales.DonorIDExit(Sender: TObject);
var
  LControl: TEdit;
begin
  LControl := TEdit(Sender);
  ValidateId(LControl);
  { TODO : Stay on the invalid id }
  ValidateSheet;
end;

procedure TDailySales.MoveOutOfSalesGrid;
begin
  if FSheetNumber <= 1 then
    CashAmount.SetFocus
  else
    SheetTotalAmount.SetFocus;
end;

procedure TDailySales.NameControlClick(Sender: TObject);
var
  lCaption: string;
  idx: Integer;
begin
  idx := TLabel(Sender).Tag;

  if FEditBlocks[idx].DonorState = disInvalid then
  begin
    FEditBlocks[idx].DonorState := disVoid;
    FEditBlocks[idx].NameControl.Caption := FEditBlocks[idx].NameControl.Caption + ' [void]';
    // FEditBlocks[idx].NameControl.Hint := 'Click to remove void status';
    ControlValid(FEditBlocks[idx].DonorIdControl.ElementID);
  end
  else if FEditBlocks[idx].DonorState = disVoid then
  begin
    lCaption := FEditBlocks[idx].NameControl.Caption;
    lCaption := lCaption.Substring(0, lCaption.Length - 7);
    FEditBlocks[idx].DonorState := disInvalid;
    FEditBlocks[idx].NameControl.Caption := lCaption;
    // FEditBlocks[idx].NameControl.Hint := MARK_AS_VOID_HINT;
    ControlInvalid(FEditBlocks[idx].DonorIdControl.ElementID);
  end;

end;

procedure TDailySales.ProcessData;
var
  lRetval: TXDataClientResponse;
  lFirstOfBatch: Boolean;
  lCreditCardOK, lCashOK, lIsNewBatch, lUpdateBanked, lPartOfLastBatch, lIsRepeatedSheet: Boolean;
  lSheetCount: Integer;
begin

  if FSheetCount = 0 then
    ThisSheetCount := 1;
  if FSheetNumber = 0 then
    ThisSheetNumber := 1;

  CalcSalesValue(True);
  lSheetCount := SheetCount.Value;

  lIsNewBatch := False;
  lFirstOfBatch := FBatchId = 0;
  lPartOfLastBatch := not lFirstOfBatch;

  // 1. Is this within a batch?
  if lFirstOfBatch then
  begin
    // Validation 2: Is this really part of the previous batch?
    lPartOfLastBatch := await(Boolean, CheckIfLastBatch);
    if lPartOfLastBatch then
    begin
      ThisSheetNumber := FHistory[FLastSheetIndex].SheetNumber + 1;
      if lSheetCount <= 1 then
        ThisSheetCount := FHistory[FLastSheetIndex].SheetCount + 1
      else
        ThisSheetCount := lSheetCount;
      FBatchId := FHistory[FLastSheetIndex].BatchId;
      SalesSheetBatchId.Value := FBatchId;
      SalesSheetCompletedBy.Value := FHistory[FLastSheetIndex].CompletedBy;
      lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(ISALESSVC_UPDATE_SHEET_COUNT,
        [FBatchId, FSheetCount]));

      // Leave these so that they can be checked CheckBankedValues
      // SalesSheetCashAndCheques.Value := FLastCash;
      // SalesSheetCreditCards.Value := FLastCards;
    end;
  end;

  if not lFirstOfBatch then
  begin
    // Validation 3: If this is sheet >=2 then check whether it really is part of the current batch, or a new one
    lIsNewBatch := await(Boolean, CheckIfNewBatch);
    if lIsNewBatch then
    begin
      lPartOfLastBatch := False;
      lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(ISALESSVC_FINALISE_SALESBATCH,
        [FBatchId]));
      FBatchId := 0;
      ThisSheetNumber := 1;
      lPartOfLastBatch := False;
    end;
    lUpdateBanked := await(Boolean, CheckBankedValues);
  end;

  if not lIsNewBatch then
  begin
    // Validation 4: Is this teh last sheet repeated?
    lIsRepeatedSheet := await(Boolean, CheckIfRepeated);
    if lIsRepeatedSheet then
      Exit;
  end;

  // Validation 5: If part of a batch, have the banked values changed?

  if lFirstOfBatch or lUpdateBanked then
  begin
    // Validation 6: If the credit card is -ve, is it ok? Only check if first time we've seen -ve for this batch;
    lCreditCardOK := await(Boolean, CheckCreditCardOK);
    if not lCreditCardOK then
    begin
      CreditCards.SetFocus;
      CreditCards.SelectAll;
      Exit;
    end;

    lCashOK := await(Boolean, CheckCashOK);
    if not lCashOK then
    begin
      CashAmount.SetFocus;
      CashAmount.SelectAll;
      Exit;
    end;

  end;

  DailySalesWait.Show;

  if FBatchId = 0 then
  begin
    lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IUTILSSVC_GETNEXTID, ['SEQ_SALESBATCH']));
    FBatchId := JS.toInteger(lRetval.ResultAsObject['value']);
    SalesSheetBatchId.Value := FBatchId;
  end;

  SalesSheet.Post;
end;

procedure TDailySales.SaleDateExit(Sender: TObject);
begin
  ValidateDate;
end;

procedure TDailySales.SaleDateKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
//  Doesn't work, see KeyPress
//  if Key = 13 then
//  begin
//    if (ssShift in Shift) then
//      SheetCount.SetFocus
//    else
//      FEditBlocks[0].DonorIdControl.SetFocus;
//  end;
end;

procedure TDailySales.SaleDateKeyPress(Sender: TObject; var Key: Char);
begin
  if (Key = #13) then //or (Key = #9): doesn't work
  begin
    FEditBlocks[0].DonorIdControl.SetFocus;
  end;
end;

procedure TDailySales.SalesAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
var
  lSheetId: Integer;
  lSheetHistory: TSheetHistory;
begin
  inc(FSheetsSubmitted);
  ShowAnAwesomeToast('Daily Sales Sheet', Format('<b>%d</b> sheets submitted', [FSheetsSubmitted]), 'far fa-shopping-bag');

  lSheetId := JS.toInteger(TJSObject(Info.Records[0].Data)['Id']);

  Sales.Close;

  lSheetHistory := TSheetHistory.Create;
  lSheetHistory.ShopRef := SalesSheetShopRef.Value;
  lSheetHistory.Salesdate := DateOf(SalesSheetSaleDate.Value);
  lSheetHistory.BatchId := FBatchId;
  lSheetHistory.SheetId := lSheetId;
  lSheetHistory.SheetCount := FSheetCount;
  lSheetHistory.SheetNumber := FSheetNumber;
  lSheetHistory.Cash := SalesSheetCashAndCheques.Value;
  lSheetHistory.Cards := SalesSheetCreditCards.Value;
  lSheetHistory.Amount := FSheetValue;
  lSheetHistory.Count := FEntryCount;
  lSheetHistory.CompletedBy := SalesSheetCompletedBy.Value;

  // for I := 0 to 15 do
  // begin
  // lSheetHistory.SalesItems[I].DonorId := FEditBlocks[I].DonorId;
  // lSheetHistory.SalesItems[I].Amount := FEditBlocks[I].SaleAmount;
  // lSheetHistory.SalesItems[I].SaleState := FEditBlocks[I].SaleState;
  // end;

  FLastSheetIndex := FHistory.Add(lSheetHistory);

  SalesSheet.Close;

  SalesSheet.Open;
  SalesSheet.Edit;

  FSheetValue := 0;
  FEntryCount := 0;

  if FSheetNumber = FSheetCount then // no more sheets in the batch
  begin
    ClearSalesSheet(True);
    FSheetCount := 0;
    ThisSheetNumber := 0;
    FBatchId := 0;
  end
  else
  begin
    ThisSheetNumber := FSheetNumber + 1;
    // SalesSheetSheetNumber.Value := FSheetNumber; //handled in ThisSheetNumber
    SalesSheetSheetCount.Value := FSheetCount;
    SalesSheetBatchId.Value := FBatchId;

    SalesSheetSaleDate.Value := FHistory[FLastSheetIndex].Salesdate;
    SalesSheetShopRef.Value := FHistory[FLastSheetIndex].ShopRef;
    SalesSheetCompletedBy.Value := FHistory[FLastSheetIndex].CompletedBy;
    SalesSheetCashAndCheques.Value := FHistory[FLastSheetIndex].Cash;
    SalesSheetCreditCards.Value := FHistory[FLastSheetIndex].Cards;

    ClearSalesSheet(False);

  end;

  DailySalesWait.Hide;

end;

procedure TDailySales.SalesSheetAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
var
  lId: Integer;
begin
  lId := JS.toInteger(TJSObject(Info.Records[0].Data)['Id']);
  UpdateSales(lId);
end;

procedure TDailySales.SalesSheetAfterPost(Dataset: TDataSet);
begin
  SalesSheet.ApplyUpdates;
end;

procedure TDailySales.SaveButtonClick(Sender: TObject);
begin
  ProcessData;
end;

procedure TDailySales.SetSheetCount(const Value: Integer);
begin
  SalesSheetSheetCount.Value := Value;
  if SheetCount.Value <> Value then
    SheetCount.Value := Value;
  FSheetCount := Value;
  FHistory.UpdateSheetCount(FBatchId, FSheetCount)
end;

procedure TDailySales.SetThisSheetNumber(const Value: Integer);
begin
  FSheetNumber := Value;
  SheetNumber.Caption := Value.ToString;
  SalesSheetSheetNumber.Value := Value;
end;

procedure TDailySales.SheetCountExit(Sender: TObject);
var
  lCount: Integer;
begin
  if Trim(SheetCount.Text) = '' then
    SheetCount.Value := 0;
  lCount := SheetCount.Value;
  if lCount = 0 then
  begin
    ThisSheetCount := 1;
    ThisSheetNumber := 1;
  end
  else
  begin
    ThisSheetCount := lCount;
    if FSheetNumber = 0 then
      ThisSheetNumber := 1;
  end;
end;

procedure TDailySales.SheetCountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if Key = key_enter then
  begin
    if (ssShift in Shift) then
      ShopRef.SetFocus
    else
      SaleDate.SetFocus;
  end;
end;

procedure TDailySales.SheetTotalAmountExit(Sender: TObject);
var
  LValue, LEnteredValue: Currency;
begin
  LValue := ShowSalesValue(True);
  LEnteredValue := RoundTo(StrToFloatDef(SheetTotalAmount.Text, 0), -2);

  if LEnteredValue = 0 then
    ControlNoValidity('SheetTotalAmount')
  else
    ControlValidity('SheetTotalAmount', LValue = LEnteredValue, True);
  ValidateSheet;
end;

procedure TDailySales.SheetTotalAmountKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if Key = 13 then
  begin
    if (ssShift in Shift) then
      CreditCards.SetFocus
    else
      CompletedBy.SetFocus;
  end
  else if (Key = key_up) then
    FEditBlocks[14].AmountControl.SetFocus
  else if (Key = key_left) then
    CreditCards.SetFocus
  else if (Key = key_right) then
    CompletedBy.SetFocus
  else if (Key = key_down) then
    CompletedBy.SetFocus;
end;

procedure TDailySales.ShopRefExit(Sender: TObject);
var
  lRef: string;
begin
  lRef := ShopRef.Text;
  if lRef.Contains('O') then
  begin
    lRef := lRef.Replace('O', '0', [rfReplaceAll]);
    ShopRef.Text := lRef;
  end;

  if ShopsLookUp.Locate('Ref', lRef, []) then
  begin
    ShopName.Caption := ShopsLookUpName.Value;
    ControlValid('ShopRef', True);
    FShopRefValid := True;
  end
  else
  begin
    ControlInvalid('ShopRef');
    ActiveControl := ShopRef;
    FShopRefValid := False;
  end;
end;

procedure TDailySales.ShopRefKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if Key = 13 then
  begin
    if not(ssShift in Shift) then
      SheetCount.SetFocus;
  end;
end;

procedure TDailySales.ShopsLookUpAfterOpen(Dataset: TDataSet);
begin
  SalesSheet.Open;
  SalesSheet.Insert;
  ValidateSheet;
  ShopRef.SetFocus;
end;

procedure TDailySales.UpdateSales(const ASalesSheetId: Integer);
var
  I: Integer;
begin

  Sales.Open;
  for I := 0 to 15 do
  begin
    if (FEditBlocks[I].DonorId > 0) or (FEditBlocks[I].DonorState = disVoid) then
    begin
      Sales.Append;
      SalesDonorId.Value := FEditBlocks[I].DonorId;
      SalesOldRef.Value := FEditBlocks[I].OldDonorId;
      SalesShopRef.Value := SalesSheetShopRef.Value;
      SalesSaleDate.Value := SalesSheetSaleDate.Value;
      SalesEnteredId.Value := FEditBlocks[I].DonorIdControl.Text;

      if FEditBlocks[I].DonorState = disVoid then
        SalesClaimStatus.Value := 'Void'
      else
        SalesClaimStatus.Value := 'Unclaimed';

      SalesAmount.Value := FEditBlocks[I].SaleAmount;
      SalesSaleDate.Value := SaleDate.Date;
      SalesSheetId2.Value := ASalesSheetId;
      SalesPageNum.Value := FSheetNumber;
      Sales.Post;
    end;

  end;

  Sales.ApplyUpdates;
end;

procedure TDailySales.ValidateDate;
begin
  FSaleDateValid := (SaleDate.Date > FMinDate) and (SaleDate.Date <= Today);
  ControlValidity('Date', FSaleDateValid);
  if not FSaleDateValid then
    SaleDate.SetFocus;
end;

procedure TDailySales.ValidateId(AControl: TEdit);
var
  lDonorId, lStatus: string;
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
  I, idx: Integer;
begin
  lDonorId := Trim(AControl.Text);
  if lDonorId.Contains('O') then
  begin
    lDonorId := lDonorId.Replace('O', '0');
    AControl.Text := lDonorId;
  end;

  if lDonorId.Chars[0] = 'G' then
    lDonorId := lDonorId.Substring(1);

  idx := AControl.Tag;

  if lDonorId = '' then
  begin
    FEditBlocks[idx].DonorId := 0;
    FEditBlocks[idx].NameControl.Caption := idx.ToString;
    FEditBlocks[idx].OldDonorId := '';
    FEditBlocks[idx].DonorState := disUnused;
    ControlNoValidity(AControl.ElementID);
    Exit;
  end;

  if lDonorId = FEditBlocks[idx].ValidatedId then
    Exit;

  FEditBlocks[idx].NameControl.OnClick := nil;
  FEditBlocks[idx].NameControl.Cursor := crDefault;
  FEditBlocks[idx].NameControl.ShowHint := False;
  THTMLHelper.removeClass(FEditBlocks[idx].NameControl.ElementID, 'is-clickable');

  for I := 0 to 15 do
  begin
    if I <> idx then
    begin
      if FEditBlocks[I].DonorIdControl.Text = lDonorId then // already checked
      begin
        FEditBlocks[idx].ValidatedId := lDonorId;
        FEditBlocks[idx].DonorId := FEditBlocks[I].DonorId;
        FEditBlocks[idx].NameControl.Caption := FEditBlocks[I].NameControl.Caption;
        FEditBlocks[idx].NameControl.Hint := FEditBlocks[I].NameControl.Hint;
        // FEditBlocks[idx].NameControl.ShowHint := True;
        FEditBlocks[idx].OldDonorId := FEditBlocks[I].OldDonorId;
        FEditBlocks[idx].DonorState := FEditBlocks[I].DonorState;
        FEditBlocks[idx].NameControl.Caption := FEditBlocks[I].NameControl.Caption;

        if FEditBlocks[idx].DonorState = disInvalid then
        begin
          ControlInvalid(AControl.ElementID);
          FEditBlocks[idx].NameControl.OnClick := FEditBlocks[I].NameControl.OnClick;
          FEditBlocks[idx].NameControl.Cursor := FEditBlocks[I].NameControl.Cursor;
          THTMLHelper.addClass(FEditBlocks[idx].NameControl.ElementID, 'is-clickable');
        end
        else if FEditBlocks[idx].DonorState = disVoid then
        begin
          ControlVoid(AControl.ElementID);
          FEditBlocks[idx].NameControl.OnClick := NameControlClick;
          FEditBlocks[idx].NameControl.Cursor := crHandPoint;
          THTMLHelper.addClass(FEditBlocks[idx].NameControl.ElementID, 'is-clickable');
        end
        else
          ControlValid(AControl.ElementID, True);

        Exit;
      end;
    end;

  end;

  FEditBlocks[idx].DonorState := disValidating;
  lRetval := await(TXDataClientResponse, WebClient.RawInvokeAsync(IDONORSVC_VALIDATE_DONORID, [lDonorId]));
  lResult := lRetval.ResultAsObject;

  FEditBlocks[idx].ValidatedId := lDonorId;

  lStatus := JS.ToString(lResult['Status']);
  if (lStatus = 'Exists') then
  begin
    ControlValid(AControl.ElementID, True);
    FEditBlocks[idx].NameControl.Caption := JS.ToString(lResult['DonorName']);
    FEditBlocks[idx].DonorId := JS.toInteger(lResult['DonorId']);
    FEditBlocks[idx].OldDonorId := JS.ToString(lResult['OldRef']);
    FEditBlocks[idx].DonorState := disValid;
  end
  else
  begin
    ControlInvalid(AControl.ElementID);
    if (lStatus = 'NotFound') or (lStatus = 'Issued') then
    begin
      FEditBlocks[idx].NameControl.Caption := CAN_MARK_AS_VOID;
      // FEditBlocks[idx].NameControl.Hint := MARK_AS_VOID_HINT;
      // FEditBlocks[idx].NameControl.ShowHint := True;
      FEditBlocks[idx].DonorState := disInvalid;
      FEditBlocks[idx].NameControl.OnClick := NameControlClick;
      FEditBlocks[idx].NameControl.Cursor := crHandPoint;
      THTMLHelper.addClass(FEditBlocks[idx].NameControl.ElementID, 'is-clickable');
    end
    else
      FEditBlocks[idx].NameControl.Caption := JS.ToString(lResult['StatusMessage']);
  end;

end;

procedure TDailySales.ValidateSheet;
begin
  ButtonValidity(SaveButton, CanSubmit);
end;

{ THistoryList }

procedure THistoryList.UpdateCashAndCards(const ABatchId: Integer; const ACash, ACards: Currency);
var
  lSheet: TSheetHistory;
begin
  if ABatchId = 0 then
    Exit;
  for lSheet in Self do
  begin
    if lSheet.BatchId = ABatchId then
    begin
      lSheet.Cash := ACash;
      lSheet.Cards := ACards;
    end;
  end;
end;

procedure THistoryList.UpdateSheetCount(const ABatchId, ASheetCount: Integer);
var
  lSheet: TSheetHistory;
begin
  if ABatchId = 0 then
    Exit;
  for lSheet in Self do
  begin
    if lSheet.BatchId = ABatchId then
      lSheet.SheetCount := ASheetCount;
  end;
end;

procedure TDailySales.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SheetNumber := TLabel.Create('SheetNumber');
  ShopName := TLabel.Create('ShopName');
  SaleDate := TDBDateTimePicker.Create('Date');
  CashAmount := TDBEdit.Create('ActualCashAmount');
  CreditCards := TDBEdit.Create('CardsAmount');
  SalesTotal := THTMLSpan.Create('SalesTotal');
  SaveButton := TButton.Create('SaveButton');
  CompletedBy := TDBEdit.Create('CompletedBy');
  ShopRef := TDBEdit.Create('ShopRef');
  DailySalesDlg := TMessageDlg.Create(Self);
  DailySalesWait := TWaitMessage.Create(Self);
  SheetCount := TSpinEdit.Create('SheetCount');
  SheetTotalAmount := TEdit.Create('SheetTotalAmount');
  SalesSheet := TXDataWebDataSet.Create(Self);
  SalesSheetId := TIntegerField.Create(Self);
  SalesSheetShopRef := TStringField.Create(Self);
  SalesSheetSaleDate := TDateField.Create(Self);
  SalesSheetCreditCards := TFloatField.Create(Self);
  SalesSheetCashAndCheques := TFloatField.Create(Self);
  SalesSheetTotalBanked := TFloatField.Create(Self);
  SalesSheetAddedBy := TIntegerField.Create(Self);
  SalesSheetDateAdded := TDateTimeField.Create(Self);
  SalesSheetLastUpdatedBy := TIntegerField.Create(Self);
  SalesSheetLastUpdated := TDateTimeField.Create(Self);
  SalesSheetCompletedBy := TStringField.Create(Self);
  SalesSheetSheetNumber := TIntegerField.Create(Self);
  SalesSheetSheetCount := TIntegerField.Create(Self);
  SalesSheetBatchId := TIntegerField.Create(Self);
  SalesSheetTotalSales := TFloatField.Create(Self);
  Sales := TXDataWebDataSet.Create(Self);
  SalesId := TIntegerField.Create(Self);
  SalesDonorId := TIntegerField.Create(Self);
  SalesShopRef := TStringField.Create(Self);
  SalesSaleDate := TDateField.Create(Self);
  SalesAmount := TFloatField.Create(Self);
  SalesCommission := TFloatField.Create(Self);
  SalesNetAmount := TFloatField.Create(Self);
  SalesClaimStatus := TStringField.Create(Self);
  SalesClaimId := TIntegerField.Create(Self);
  SalesClaimRef := TStringField.Create(Self);
  SalesClaimDate := TDateField.Create(Self);
  SalesClaimStatusChanged := TDateField.Create(Self);
  SalesNotificationId := TIntegerField.Create(Self);
  SalesRefundDate := TDateField.Create(Self);
  SalesRefundId := TIntegerField.Create(Self);
  SalesAddedBy := TIntegerField.Create(Self);
  SalesDateAdded := TDateTimeField.Create(Self);
  SalesLastUpdatedBy := TIntegerField.Create(Self);
  SalesLastUpdated := TDateTimeField.Create(Self);
  SalesSheetId2 := TIntegerField.Create(Self);
  SalesPageNum := TIntegerField.Create(Self);
  SalesTaxYear := TIntegerField.Create(Self);
  SalesOldRef := TStringField.Create(Self);
  SalesEnteredId := TStringField.Create(Self);
  SalesNotificationDate := TDateField.Create(Self);
  SalesNotificationSent := TBooleanField.Create(Self);
  SalesSalesTaxYear := TIntegerField.Create(Self);
  SalesSalesYear := TIntegerField.Create(Self);
  SalesGiftAidValue := TFloatField.Create(Self);
  SalesCompanyYear := TIntegerField.Create(Self);
  ShopsLookUp := TXDataWebDataSet.Create(Self);
  ShopsLookUpRef := TStringField.Create(Self);
  ShopsLookUpName := TStringField.Create(Self);
  SalesSheetSource := TDataSource.Create(Self);
  WebClient := TXDataWebClient.Create(Self);

  SheetNumber.BeforeLoadDFMValues;
  ShopName.BeforeLoadDFMValues;
  CloseThisButton.BeforeLoadDFMValues;
  SaleDate.BeforeLoadDFMValues;
  CashAmount.BeforeLoadDFMValues;
  CreditCards.BeforeLoadDFMValues;
  SalesTotal.BeforeLoadDFMValues;
  SaveButton.BeforeLoadDFMValues;
  CompletedBy.BeforeLoadDFMValues;
  ShopRef.BeforeLoadDFMValues;
  DailySalesDlg.BeforeLoadDFMValues;
  DailySalesWait.BeforeLoadDFMValues;
  SheetCount.BeforeLoadDFMValues;
  SheetTotalAmount.BeforeLoadDFMValues;
  SalesSheet.BeforeLoadDFMValues;
  SalesSheetId.BeforeLoadDFMValues;
  SalesSheetShopRef.BeforeLoadDFMValues;
  SalesSheetSaleDate.BeforeLoadDFMValues;
  SalesSheetCreditCards.BeforeLoadDFMValues;
  SalesSheetCashAndCheques.BeforeLoadDFMValues;
  SalesSheetTotalBanked.BeforeLoadDFMValues;
  SalesSheetAddedBy.BeforeLoadDFMValues;
  SalesSheetDateAdded.BeforeLoadDFMValues;
  SalesSheetLastUpdatedBy.BeforeLoadDFMValues;
  SalesSheetLastUpdated.BeforeLoadDFMValues;
  SalesSheetCompletedBy.BeforeLoadDFMValues;
  SalesSheetSheetNumber.BeforeLoadDFMValues;
  SalesSheetSheetCount.BeforeLoadDFMValues;
  SalesSheetBatchId.BeforeLoadDFMValues;
  SalesSheetTotalSales.BeforeLoadDFMValues;
  Sales.BeforeLoadDFMValues;
  SalesId.BeforeLoadDFMValues;
  SalesDonorId.BeforeLoadDFMValues;
  SalesShopRef.BeforeLoadDFMValues;
  SalesSaleDate.BeforeLoadDFMValues;
  SalesAmount.BeforeLoadDFMValues;
  SalesCommission.BeforeLoadDFMValues;
  SalesNetAmount.BeforeLoadDFMValues;
  SalesClaimStatus.BeforeLoadDFMValues;
  SalesClaimId.BeforeLoadDFMValues;
  SalesClaimRef.BeforeLoadDFMValues;
  SalesClaimDate.BeforeLoadDFMValues;
  SalesClaimStatusChanged.BeforeLoadDFMValues;
  SalesNotificationId.BeforeLoadDFMValues;
  SalesRefundDate.BeforeLoadDFMValues;
  SalesRefundId.BeforeLoadDFMValues;
  SalesAddedBy.BeforeLoadDFMValues;
  SalesDateAdded.BeforeLoadDFMValues;
  SalesLastUpdatedBy.BeforeLoadDFMValues;
  SalesLastUpdated.BeforeLoadDFMValues;
  SalesSheetId2.BeforeLoadDFMValues;
  SalesPageNum.BeforeLoadDFMValues;
  SalesTaxYear.BeforeLoadDFMValues;
  SalesOldRef.BeforeLoadDFMValues;
  SalesEnteredId.BeforeLoadDFMValues;
  SalesNotificationDate.BeforeLoadDFMValues;
  SalesNotificationSent.BeforeLoadDFMValues;
  SalesSalesTaxYear.BeforeLoadDFMValues;
  SalesSalesYear.BeforeLoadDFMValues;
  SalesGiftAidValue.BeforeLoadDFMValues;
  SalesCompanyYear.BeforeLoadDFMValues;
  ShopsLookUp.BeforeLoadDFMValues;
  ShopsLookUpRef.BeforeLoadDFMValues;
  ShopsLookUpName.BeforeLoadDFMValues;
  SalesSheetSource.BeforeLoadDFMValues;
  WebClient.BeforeLoadDFMValues;
  try
    Width := 624;
    Height := 445;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    SheetNumber.SetParentComponent(Self);
    SheetNumber.Name := 'SheetNumber';
    SheetNumber.Left := 272;
    SheetNumber.Top := 21;
    SheetNumber.Width := 6;
    SheetNumber.Height := 15;
    SheetNumber.Margins.Left := 2;
    SheetNumber.Margins.Top := 2;
    SheetNumber.Margins.Right := 2;
    SheetNumber.Margins.Bottom := 2;
    SheetNumber.Alignment := taRightJustify;
    SheetNumber.Caption := '0';
    SheetNumber.ElementFont := efCSS;
    SheetNumber.HeightStyle := ssAuto;
    SheetNumber.HeightPercent := 100.000000000000000000;
    SheetNumber.HTMLType := tSPAN;
    SheetNumber.WidthPercent := 100.000000000000000000;
    ShopName.SetParentComponent(Self);
    ShopName.Name := 'ShopName';
    ShopName.Left := 176;
    ShopName.Top := 149;
    ShopName.Width := 3;
    ShopName.Height := 15;
    ShopName.Margins.Left := 2;
    ShopName.Margins.Top := 2;
    ShopName.Margins.Right := 2;
    ShopName.Margins.Bottom := 2;
    ShopName.ElementFont := efCSS;
    ShopName.ElementPosition := epIgnore;
    ShopName.HeightStyle := ssAuto;
    ShopName.HeightPercent := 100.000000000000000000;
    ShopName.HTMLType := tSPAN;
    ShopName.WidthStyle := ssAuto;
    ShopName.WidthPercent := 100.000000000000000000;
    CloseThisButton.Left := 592;
    CloseThisButton.Top := 8;
    CloseThisButton.Width := 32;
    CloseThisButton.Height := 25;
    CloseThisButton.Margins.Left := 3;
    CloseThisButton.Margins.Top := 3;
    CloseThisButton.Margins.Right := 3;
    CloseThisButton.Margins.Bottom := 3;
    SaleDate.SetParentComponent(Self);
    SaleDate.Name := 'SaleDate';
    SaleDate.Left := 372;
    SaleDate.Top := 15;
    SaleDate.Width := 113;
    SaleDate.Height := 15;
    SaleDate.Margins.Left := 2;
    SaleDate.Margins.Top := 2;
    SaleDate.Margins.Right := 2;
    SaleDate.Margins.Bottom := 2;
    SaleDate.ElementClassName := 'form-control';
    SaleDate.HeightStyle := ssAuto;
    SaleDate.WidthStyle := ssAuto;
    SaleDate.BorderStyle := bsSingle;
    SaleDate.ChildOrder := 1;
    SaleDate.Color := clWhite;
    SaleDate.Date := 44483.612014027780000000;
    SaleDate.ElementFont := efCSS;
    SaleDate.Role := '';
    SaleDate.TabOrder := 3;
    SaleDate.Text := '';
    SetEvent(SaleDate, Self, 'OnExit', 'SaleDateExit');
    SetEvent(SaleDate, Self, 'OnKeyDown', 'SaleDateKeyDown');
    SetEvent(SaleDate, Self, 'OnKeyPress', 'SaleDateKeyPress');
    SaleDate.DataField := 'SaleDate';
    SaleDate.DataSource := SalesSheetSource;
    CashAmount.SetParentComponent(Self);
    CashAmount.Name := 'CashAmount';
    CashAmount.Left := 235;
    CashAmount.Top := 56;
    CashAmount.Width := 80;
    CashAmount.Height := 15;
    CashAmount.Margins.Left := 2;
    CashAmount.Margins.Top := 2;
    CashAmount.Margins.Right := 2;
    CashAmount.Margins.Bottom := 2;
    CashAmount.ChildOrder := 2;
    CashAmount.EditType := weSignedFloat;
    CashAmount.ElementClassName := 'form-control';
    CashAmount.ElementFont := efCSS;
    CashAmount.HeightStyle := ssAuto;
    CashAmount.HeightPercent := 100.000000000000000000;
    CashAmount.Text := 'CashAmount';
    CashAmount.WidthStyle := ssAuto;
    CashAmount.WidthPercent := 100.000000000000000000;
    SetEvent(CashAmount, Self, 'OnExit', 'CashAmountExit');
    SetEvent(CashAmount, Self, 'OnKeyDown', 'CashAmountKeyDown');
    CashAmount.DataField := 'CashAndCheques';
    CashAmount.DataSource := SalesSheetSource;
    CreditCards.SetParentComponent(Self);
    CreditCards.Name := 'CreditCards';
    CreditCards.Left := 319;
    CreditCards.Top := 56;
    CreditCards.Width := 81;
    CreditCards.Height := 15;
    CreditCards.Margins.Left := 2;
    CreditCards.Margins.Top := 2;
    CreditCards.Margins.Right := 2;
    CreditCards.Margins.Bottom := 2;
    CreditCards.ChildOrder := 3;
    CreditCards.EditType := weSignedFloat;
    CreditCards.ElementClassName := 'form-control';
    CreditCards.ElementFont := efCSS;
    CreditCards.HeightStyle := ssAuto;
    CreditCards.HeightPercent := 100.000000000000000000;
    CreditCards.Text := 'CreditCards';
    CreditCards.WidthPercent := 100.000000000000000000;
    SetEvent(CreditCards, Self, 'OnExit', 'CashAmountExit');
    SetEvent(CreditCards, Self, 'OnKeyDown', 'CreditCardsKeyDown');
    CreditCards.DataField := 'CreditCards';
    CreditCards.DataSource := SalesSheetSource;
    SalesTotal.SetParentComponent(Self);
    SalesTotal.Name := 'SalesTotal';
    SalesTotal.Left := 43;
    SalesTotal.Top := 112;
    SalesTotal.Width := 66;
    SalesTotal.Height := 17;
    SalesTotal.Margins.Left := 2;
    SalesTotal.Margins.Top := 2;
    SalesTotal.Margins.Right := 2;
    SalesTotal.Margins.Bottom := 2;
    SalesTotal.HeightStyle := ssAuto;
    SalesTotal.WidthStyle := ssAuto;
    SalesTotal.ChildOrder := 5;
    SalesTotal.ElementPosition := epIgnore;
    SalesTotal.ElementFont := efCSS;
    SalesTotal.Role := '';
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 75;
    SaveButton.Top := 261;
    SaveButton.Width := 64;
    SaveButton.Height := 17;
    SaveButton.Margins.Left := 2;
    SaveButton.Margins.Top := 2;
    SaveButton.Margins.Right := 2;
    SaveButton.Margins.Bottom := 2;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 5;
    SaveButton.ElementClassName := 'btn btn-secondary';
    SaveButton.ElementFont := efCSS;
    SaveButton.HeightStyle := ssAuto;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CompletedBy.SetParentComponent(Self);
    CompletedBy.Name := 'CompletedBy';
    CompletedBy.Left := 235;
    CompletedBy.Top := 91;
    CompletedBy.Width := 165;
    CompletedBy.Height := 14;
    CompletedBy.Margins.Left := 2;
    CompletedBy.Margins.Top := 2;
    CompletedBy.Margins.Right := 2;
    CompletedBy.Margins.Bottom := 2;
    CompletedBy.ChildOrder := 7;
    CompletedBy.ElementClassName := 'form-control';
    CompletedBy.ElementFont := efCSS;
    CompletedBy.ElementPosition := epIgnore;
    CompletedBy.HeightStyle := ssAuto;
    CompletedBy.HeightPercent := 100.000000000000000000;
    CompletedBy.Text := 'CompletedBy';
    CompletedBy.WidthStyle := ssAuto;
    CompletedBy.WidthPercent := 100.000000000000000000;
    SetEvent(CompletedBy, Self, 'OnKeyDown', 'CompletedByKeyDown');
    CompletedBy.DataField := 'CompletedBy';
    CompletedBy.DataSource := SalesSheetSource;
    ShopRef.SetParentComponent(Self);
    ShopRef.Name := 'ShopRef';
    ShopRef.Left := 194;
    ShopRef.Top := 19;
    ShopRef.Width := 36;
    ShopRef.Height := 15;
    ShopRef.Margins.Left := 2;
    ShopRef.Margins.Top := 2;
    ShopRef.Margins.Right := 2;
    ShopRef.Margins.Bottom := 2;
    ShopRef.CharCase := wecUpperCase;
    ShopRef.ChildOrder := 10;
    ShopRef.ElementClassName := 'form-control id-control';
    ShopRef.ElementFont := efCSS;
    ShopRef.ElementPosition := epIgnore;
    ShopRef.HeightStyle := ssAuto;
    ShopRef.HeightPercent := 100.000000000000000000;
    ShopRef.TabOrder := 1;
    ShopRef.Text := 'ShopRef';
    ShopRef.TextHint := 'Shop Ref';
    ShopRef.WidthStyle := ssAuto;
    ShopRef.WidthPercent := 100.000000000000000000;
    SetEvent(ShopRef, Self, 'OnExit', 'ShopRefExit');
    SetEvent(ShopRef, Self, 'OnKeyDown', 'ShopRefKeyDown');
    ShopRef.DataField := 'ShopRef';
    ShopRef.DataSource := SalesSheetSource;
    DailySalesDlg.SetParentComponent(Self);
    DailySalesDlg.Name := 'DailySalesDlg';
    DailySalesDlg.Left := 459;
    DailySalesDlg.Top := 55;
    DailySalesDlg.Width := 24;
    DailySalesDlg.Height := 24;
    DailySalesDlg.Margins.Left := 2;
    DailySalesDlg.Margins.Top := 2;
    DailySalesDlg.Margins.Right := 2;
    DailySalesDlg.Margins.Bottom := 2;
    DailySalesDlg.HeightStyle := ssAuto;
    DailySalesDlg.WidthStyle := ssAuto;
    DailySalesDlg.Buttons := [];
    DailySalesDlg.DialogType := mtConfirmation;
    DailySalesDlg.Opacity := 0.200000000000000000;
    DailySalesDlg.ElementButtonClassName := 'btn btn-primary';
    DailySalesDlg.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    DailySalesDlg.ElementTitleClassName := 'text-body';
    DailySalesDlg.ElementContentClassName := 'text-body';
    DailySalesWait.SetParentComponent(Self);
    DailySalesWait.Name := 'DailySalesWait';
    DailySalesWait.Left := 182;
    DailySalesWait.Top := 149;
    DailySalesWait.Width := 24;
    DailySalesWait.Height := 24;
    DailySalesWait.Margins.Left := 2;
    DailySalesWait.Margins.Top := 2;
    DailySalesWait.Margins.Right := 2;
    DailySalesWait.Margins.Bottom := 2;
    DailySalesWait.HeightStyle := ssAuto;
    DailySalesWait.WidthStyle := ssAuto;
    DailySalesWait.Opacity := 0.200000000000000000;
    DailySalesWait.PictureURL := 'images/Whirligig.gif';
    SheetCount.SetParentComponent(Self);
    SheetCount.Name := 'SheetCount';
    SheetCount.Left := 288;
    SheetCount.Top := 19;
    SheetCount.Width := 27;
    SheetCount.Height := 15;
    SheetCount.Margins.Left := 2;
    SheetCount.Margins.Top := 2;
    SheetCount.Margins.Right := 2;
    SheetCount.Margins.Bottom := 2;
    SheetCount.ElementClassName := 'form-control';
    SheetCount.HeightStyle := ssAuto;
    SheetCount.WidthStyle := ssAuto;
    SheetCount.AutoSize := False;
    SheetCount.BorderStyle := bsSingle;
    SheetCount.ChildOrder := 13;
    SheetCount.Color := clWhite;
    SheetCount.ElementFont := efCSS;
    SheetCount.ElementPosition := epIgnore;
    SheetCount.Increment := 1;
    SheetCount.MaxValue := 100;
    SheetCount.MinValue := 0;
    SheetCount.Role := '';
    SheetCount.Value := 0;
    SetEvent(SheetCount, Self, 'OnExit', 'SheetCountExit');
    SetEvent(SheetCount, Self, 'OnKeyDown', 'SheetCountKeyDown');
    SheetTotalAmount.SetParentComponent(Self);
    SheetTotalAmount.Name := 'SheetTotalAmount';
    SheetTotalAmount.Left := 432;
    SheetTotalAmount.Top := 91;
    SheetTotalAmount.Width := 81;
    SheetTotalAmount.Height := 14;
    SheetTotalAmount.Margins.Left := 2;
    SheetTotalAmount.Margins.Top := 2;
    SheetTotalAmount.Margins.Right := 2;
    SheetTotalAmount.Margins.Bottom := 2;
    SheetTotalAmount.Alignment := taRightJustify;
    SheetTotalAmount.ChildOrder := 13;
    SheetTotalAmount.EditType := weFloat;
    SheetTotalAmount.ElementClassName := 'form-control';
    SheetTotalAmount.ElementFont := efCSS;
    SheetTotalAmount.ElementPosition := epIgnore;
    SheetTotalAmount.HeightStyle := ssAuto;
    SheetTotalAmount.HeightPercent := 100.000000000000000000;
    SheetTotalAmount.WidthStyle := ssAuto;
    SheetTotalAmount.WidthPercent := 100.000000000000000000;
    SetEvent(SheetTotalAmount, Self, 'OnExit', 'SheetTotalAmountExit');
    SetEvent(SheetTotalAmount, Self, 'OnKeyDown', 'SheetTotalAmountKeyDown');
    SalesSheet.SetParentComponent(Self);
    SalesSheet.Name := 'SalesSheet';
    SalesSheet.AfterApplyUpdates := SalesSheetAfterApplyUpdates;
    SalesSheet.AfterPost := SalesSheetAfterPost;
    SalesSheet.EntitySetName := 'SalesSheet';
    SalesSheet.Connection := MainData.DataConnection;
    SalesSheet.Left := 80;
    SalesSheet.Top := 40;
    SalesSheetId.SetParentComponent(SalesSheet);
    SalesSheetId.Name := 'SalesSheetId';
    SalesSheetId.FieldName := 'Id';
    SalesSheetShopRef.SetParentComponent(SalesSheet);
    SalesSheetShopRef.Name := 'SalesSheetShopRef';
    SalesSheetShopRef.FieldName := 'ShopRef';
    SalesSheetShopRef.Size := 10;
    SalesSheetSaleDate.SetParentComponent(SalesSheet);
    SalesSheetSaleDate.Name := 'SalesSheetSaleDate';
    SalesSheetSaleDate.FieldName := 'SaleDate';
    SalesSheetCreditCards.SetParentComponent(SalesSheet);
    SalesSheetCreditCards.Name := 'SalesSheetCreditCards';
    SalesSheetCreditCards.FieldName := 'CreditCards';
    SalesSheetCreditCards.DisplayFormat := '#,##0.00';
    SalesSheetCreditCards.EditFormat := '0.00';
    SalesSheetCashAndCheques.SetParentComponent(SalesSheet);
    SalesSheetCashAndCheques.Name := 'SalesSheetCashAndCheques';
    SalesSheetCashAndCheques.FieldName := 'CashAndCheques';
    SalesSheetCashAndCheques.DisplayFormat := '#,##0.00';
    SalesSheetCashAndCheques.EditFormat := '0.00';
    SalesSheetTotalBanked.SetParentComponent(SalesSheet);
    SalesSheetTotalBanked.Name := 'SalesSheetTotalBanked';
    SalesSheetTotalBanked.FieldName := 'TotalBanked';
    SalesSheetAddedBy.SetParentComponent(SalesSheet);
    SalesSheetAddedBy.Name := 'SalesSheetAddedBy';
    SalesSheetAddedBy.FieldName := 'AddedBy';
    SalesSheetDateAdded.SetParentComponent(SalesSheet);
    SalesSheetDateAdded.Name := 'SalesSheetDateAdded';
    SalesSheetDateAdded.FieldName := 'DateAdded';
    SalesSheetLastUpdatedBy.SetParentComponent(SalesSheet);
    SalesSheetLastUpdatedBy.Name := 'SalesSheetLastUpdatedBy';
    SalesSheetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    SalesSheetLastUpdated.SetParentComponent(SalesSheet);
    SalesSheetLastUpdated.Name := 'SalesSheetLastUpdated';
    SalesSheetLastUpdated.FieldName := 'LastUpdated';
    SalesSheetCompletedBy.SetParentComponent(SalesSheet);
    SalesSheetCompletedBy.Name := 'SalesSheetCompletedBy';
    SalesSheetCompletedBy.FieldName := 'CompletedBy';
    SalesSheetCompletedBy.Size := 50;
    SalesSheetSheetNumber.SetParentComponent(SalesSheet);
    SalesSheetSheetNumber.Name := 'SalesSheetSheetNumber';
    SalesSheetSheetNumber.FieldName := 'SheetNumber';
    SalesSheetSheetCount.SetParentComponent(SalesSheet);
    SalesSheetSheetCount.Name := 'SalesSheetSheetCount';
    SalesSheetSheetCount.FieldName := 'SheetCount';
    SalesSheetBatchId.SetParentComponent(SalesSheet);
    SalesSheetBatchId.Name := 'SalesSheetBatchId';
    SalesSheetBatchId.FieldName := 'BatchId';
    SalesSheetTotalSales.SetParentComponent(SalesSheet);
    SalesSheetTotalSales.Name := 'SalesSheetTotalSales';
    SalesSheetTotalSales.FieldName := 'TotalSales';
    Sales.SetParentComponent(Self);
    Sales.Name := 'Sales';
    Sales.AfterApplyUpdates := SalesAfterApplyUpdates;
    Sales.EntitySetName := 'Sale';
    Sales.Connection := MainData.DataConnection;
    Sales.Left := 144;
    Sales.Top := 40;
    SalesId.SetParentComponent(Sales);
    SalesId.Name := 'SalesId';
    SalesId.FieldName := 'Id';
    SalesDonorId.SetParentComponent(Sales);
    SalesDonorId.Name := 'SalesDonorId';
    SalesDonorId.FieldName := 'DonorId';
    SalesShopRef.SetParentComponent(Sales);
    SalesShopRef.Name := 'SalesShopRef';
    SalesShopRef.FieldName := 'ShopRef';
    SalesShopRef.Size := 10;
    SalesSaleDate.SetParentComponent(Sales);
    SalesSaleDate.Name := 'SalesSaleDate';
    SalesSaleDate.FieldName := 'SaleDate';
    SalesAmount.SetParentComponent(Sales);
    SalesAmount.Name := 'SalesAmount';
    SalesAmount.FieldName := 'Amount';
    SalesCommission.SetParentComponent(Sales);
    SalesCommission.Name := 'SalesCommission';
    SalesCommission.FieldName := 'Commission';
    SalesNetAmount.SetParentComponent(Sales);
    SalesNetAmount.Name := 'SalesNetAmount';
    SalesNetAmount.FieldName := 'NetAmount';
    SalesClaimStatus.SetParentComponent(Sales);
    SalesClaimStatus.Name := 'SalesClaimStatus';
    SalesClaimStatus.FieldName := 'ClaimStatus';
    SalesClaimStatus.Size := 18;
    SalesClaimId.SetParentComponent(Sales);
    SalesClaimId.Name := 'SalesClaimId';
    SalesClaimId.FieldName := 'ClaimId';
    SalesClaimRef.SetParentComponent(Sales);
    SalesClaimRef.Name := 'SalesClaimRef';
    SalesClaimRef.FieldName := 'ClaimRef';
    SalesClaimRef.Size := 255;
    SalesClaimDate.SetParentComponent(Sales);
    SalesClaimDate.Name := 'SalesClaimDate';
    SalesClaimDate.FieldName := 'ClaimDate';
    SalesClaimStatusChanged.SetParentComponent(Sales);
    SalesClaimStatusChanged.Name := 'SalesClaimStatusChanged';
    SalesClaimStatusChanged.FieldName := 'ClaimStatusChanged';
    SalesNotificationId.SetParentComponent(Sales);
    SalesNotificationId.Name := 'SalesNotificationId';
    SalesNotificationId.FieldName := 'NotificationId';
    SalesRefundDate.SetParentComponent(Sales);
    SalesRefundDate.Name := 'SalesRefundDate';
    SalesRefundDate.FieldName := 'RefundDate';
    SalesRefundId.SetParentComponent(Sales);
    SalesRefundId.Name := 'SalesRefundId';
    SalesRefundId.FieldName := 'RefundId';
    SalesAddedBy.SetParentComponent(Sales);
    SalesAddedBy.Name := 'SalesAddedBy';
    SalesAddedBy.FieldName := 'AddedBy';
    SalesDateAdded.SetParentComponent(Sales);
    SalesDateAdded.Name := 'SalesDateAdded';
    SalesDateAdded.FieldName := 'DateAdded';
    SalesLastUpdatedBy.SetParentComponent(Sales);
    SalesLastUpdatedBy.Name := 'SalesLastUpdatedBy';
    SalesLastUpdatedBy.FieldName := 'LastUpdatedBy';
    SalesLastUpdated.SetParentComponent(Sales);
    SalesLastUpdated.Name := 'SalesLastUpdated';
    SalesLastUpdated.FieldName := 'LastUpdated';
    SalesSheetId2.SetParentComponent(Sales);
    SalesSheetId2.Name := 'SalesSheetId2';
    SalesSheetId2.FieldName := 'SheetId';
    SalesPageNum.SetParentComponent(Sales);
    SalesPageNum.Name := 'SalesPageNum';
    SalesPageNum.FieldName := 'PageNum';
    SalesTaxYear.SetParentComponent(Sales);
    SalesTaxYear.Name := 'SalesTaxYear';
    SalesTaxYear.FieldName := 'TaxYear';
    SalesOldRef.SetParentComponent(Sales);
    SalesOldRef.Name := 'SalesOldRef';
    SalesOldRef.FieldName := 'OldRef';
    SalesOldRef.Size := 10;
    SalesEnteredId.SetParentComponent(Sales);
    SalesEnteredId.Name := 'SalesEnteredId';
    SalesEnteredId.FieldName := 'EnteredId';
    SalesEnteredId.Size := 255;
    SalesNotificationDate.SetParentComponent(Sales);
    SalesNotificationDate.Name := 'SalesNotificationDate';
    SalesNotificationDate.FieldName := 'NotificationDate';
    SalesNotificationSent.SetParentComponent(Sales);
    SalesNotificationSent.Name := 'SalesNotificationSent';
    SalesNotificationSent.FieldName := 'NotificationSent';
    SalesSalesTaxYear.SetParentComponent(Sales);
    SalesSalesTaxYear.Name := 'SalesSalesTaxYear';
    SalesSalesTaxYear.FieldName := 'SalesTaxYear';
    SalesSalesYear.SetParentComponent(Sales);
    SalesSalesYear.Name := 'SalesSalesYear';
    SalesSalesYear.FieldName := 'SalesYear';
    SalesGiftAidValue.SetParentComponent(Sales);
    SalesGiftAidValue.Name := 'SalesGiftAidValue';
    SalesGiftAidValue.FieldName := 'GiftAidValue';
    SalesCompanyYear.SetParentComponent(Sales);
    SalesCompanyYear.Name := 'SalesCompanyYear';
    SalesCompanyYear.FieldName := 'CompanyYear';
    ShopsLookUp.SetParentComponent(Self);
    ShopsLookUp.Name := 'ShopsLookUp';
    ShopsLookUp.AfterOpen := ShopsLookUpAfterOpen;
    ShopsLookUp.EntitySetName := 'Shop';
    ShopsLookUp.Connection := MainData.DataConnection;
    ShopsLookUp.Left := 216;
    ShopsLookUp.Top := 41;
    ShopsLookUpRef.SetParentComponent(ShopsLookUp);
    ShopsLookUpRef.Name := 'ShopsLookUpRef';
    ShopsLookUpRef.FieldName := 'Ref';
    ShopsLookUpRef.Size := 5;
    ShopsLookUpName.SetParentComponent(ShopsLookUp);
    ShopsLookUpName.Name := 'ShopsLookUpName';
    ShopsLookUpName.FieldName := 'Name';
    ShopsLookUpName.Size := 250;
    SalesSheetSource.SetParentComponent(Self);
    SalesSheetSource.Name := 'SalesSheetSource';
    SalesSheetSource.DataSet := SalesSheet;
    SalesSheetSource.Left := 56;
    SalesSheetSource.Top := 256;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := MainData.DataConnection;
    WebClient.Left := 192;
    WebClient.Top := 252;
  finally
    SheetNumber.AfterLoadDFMValues;
    ShopName.AfterLoadDFMValues;
    CloseThisButton.AfterLoadDFMValues;
    SaleDate.AfterLoadDFMValues;
    CashAmount.AfterLoadDFMValues;
    CreditCards.AfterLoadDFMValues;
    SalesTotal.AfterLoadDFMValues;
    SaveButton.AfterLoadDFMValues;
    CompletedBy.AfterLoadDFMValues;
    ShopRef.AfterLoadDFMValues;
    DailySalesDlg.AfterLoadDFMValues;
    DailySalesWait.AfterLoadDFMValues;
    SheetCount.AfterLoadDFMValues;
    SheetTotalAmount.AfterLoadDFMValues;
    SalesSheet.AfterLoadDFMValues;
    SalesSheetId.AfterLoadDFMValues;
    SalesSheetShopRef.AfterLoadDFMValues;
    SalesSheetSaleDate.AfterLoadDFMValues;
    SalesSheetCreditCards.AfterLoadDFMValues;
    SalesSheetCashAndCheques.AfterLoadDFMValues;
    SalesSheetTotalBanked.AfterLoadDFMValues;
    SalesSheetAddedBy.AfterLoadDFMValues;
    SalesSheetDateAdded.AfterLoadDFMValues;
    SalesSheetLastUpdatedBy.AfterLoadDFMValues;
    SalesSheetLastUpdated.AfterLoadDFMValues;
    SalesSheetCompletedBy.AfterLoadDFMValues;
    SalesSheetSheetNumber.AfterLoadDFMValues;
    SalesSheetSheetCount.AfterLoadDFMValues;
    SalesSheetBatchId.AfterLoadDFMValues;
    SalesSheetTotalSales.AfterLoadDFMValues;
    Sales.AfterLoadDFMValues;
    SalesId.AfterLoadDFMValues;
    SalesDonorId.AfterLoadDFMValues;
    SalesShopRef.AfterLoadDFMValues;
    SalesSaleDate.AfterLoadDFMValues;
    SalesAmount.AfterLoadDFMValues;
    SalesCommission.AfterLoadDFMValues;
    SalesNetAmount.AfterLoadDFMValues;
    SalesClaimStatus.AfterLoadDFMValues;
    SalesClaimId.AfterLoadDFMValues;
    SalesClaimRef.AfterLoadDFMValues;
    SalesClaimDate.AfterLoadDFMValues;
    SalesClaimStatusChanged.AfterLoadDFMValues;
    SalesNotificationId.AfterLoadDFMValues;
    SalesRefundDate.AfterLoadDFMValues;
    SalesRefundId.AfterLoadDFMValues;
    SalesAddedBy.AfterLoadDFMValues;
    SalesDateAdded.AfterLoadDFMValues;
    SalesLastUpdatedBy.AfterLoadDFMValues;
    SalesLastUpdated.AfterLoadDFMValues;
    SalesSheetId2.AfterLoadDFMValues;
    SalesPageNum.AfterLoadDFMValues;
    SalesTaxYear.AfterLoadDFMValues;
    SalesOldRef.AfterLoadDFMValues;
    SalesEnteredId.AfterLoadDFMValues;
    SalesNotificationDate.AfterLoadDFMValues;
    SalesNotificationSent.AfterLoadDFMValues;
    SalesSalesTaxYear.AfterLoadDFMValues;
    SalesSalesYear.AfterLoadDFMValues;
    SalesGiftAidValue.AfterLoadDFMValues;
    SalesCompanyYear.AfterLoadDFMValues;
    ShopsLookUp.AfterLoadDFMValues;
    ShopsLookUpRef.AfterLoadDFMValues;
    ShopsLookUpName.AfterLoadDFMValues;
    SalesSheetSource.AfterLoadDFMValues;
    WebClient.AfterLoadDFMValues;
  end;
end;

end.
